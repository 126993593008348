export class LoaderState{
    private outstandingOperations: number = 0;

    private listeners: ((isActive: boolean) => void)[] = [];

    private constructor(){}

    public show(){
        this.outstandingOperations++;

        this.notifyAll();
    }

    public hide(){
        if(this.outstandingOperations > 0)
            this.outstandingOperations--;

        this.notifyAll();
    }

    public addListener(listener: (isActive: boolean) => void){
        if(this.listeners.includes(listener))
            return;

        this.listeners.push(listener);
    }

    private notifyAll(){
        this.listeners.forEach(listener => {
            try{
                listener(this.outstandingOperations > 0);
            }catch(err){
                console.error(err);
            }
        })
    }

    public static readonly instance = new LoaderState();
}