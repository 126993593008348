import { IContentService } from "../interfaces/IContentService";
import { ImageType } from "../models/enums";
import * as axios from "axios";
import { ApiRouteKeys } from "../settings";

export class ContentService implements IContentService {
    constructor(private baseUrl: string, tenantId: string) {
        if ((baseUrl = (baseUrl ?? "").trim()).length == 0)
            throw new Error("base url is mandatory");

        if ((tenantId = (tenantId ?? "").trim()).length == 0)
            throw new Error("tenant is mandatory");

        axios.default.defaults.headers = { "tenantId": tenantId };
    }

    async saveImageAsync(entityId: string, imageType: ImageType, file: File): Promise<void> {
        var formData = new FormData();
        var fileName = (imageType == ImageType.Logo || imageType == ImageType.Favicon) ? "logo" : "profilePic";
        formData.append(fileName, file);
        await axios.default.put(this.getUrl(entityId, imageType), formData);
    }

    async purgeImageAsync(entityId: string, imageType: ImageType): Promise<void> {
        await axios.default.put(this.getUrl(entityId, imageType), { removeFile: true });
    }

    async getImageAsync(entityId: string, filePrefix: string): Promise<string> {
        const url = `${this.baseUrl}/${ApiRouteKeys.Content}/${ApiRouteKeys.Logos}/${entityId}/${filePrefix}`;
        const response = await axios.default.get(url);
        return response?.data?.url || "";
    }

    private getUrl(entityId: string, imageType: ImageType) {
        const contentTypeRouteKey = (imageType == ImageType.Logo || imageType == ImageType.Favicon) ? ApiRouteKeys.Logos : ApiRouteKeys.ProfilePics;
        return `${this.baseUrl}/${ApiRouteKeys.Content}/${contentTypeRouteKey}/${entityId}${imageType === ImageType.Favicon ? "?favicon=true" : ""}`;
    }
}