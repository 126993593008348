import { v4 } from 'uuid';
import { PagedList } from "../models/pagedList";
import { ServiceBase } from "./ServiceBase";
import * as axios from "axios";
import { IAuthState } from "../interfaces/IAuthState";
import { ICodeGenerateProvider } from '../interfaces/ICodeGenerator';
import { CodeGenerateCreationRq, CodeGenerateDeletionRq, CodeGenerateFetchRq, CodeGenerateListingRq, CodeGenerateUpdationRq, CodeGeneratorStatusUpdationRq } from '../models/code-generate-request';
import { ICodeGenerator } from '../models/codeGenerator';

export class CodeGenerateProvider extends ServiceBase implements ICodeGenerateProvider {
  private static codeGenArr: ICodeGenerator[] = [];
  constructor(baseUrl: string, tenantId: string, authState: IAuthState) {
    super(baseUrl, tenantId, authState);
  }

  public getApiBaseUrl(): string {
    return this.baseUrl;
  }

  createCodeGenerateAsync(req: CodeGenerateCreationRq): Promise<ICodeGenerator> {
    return this.safelyExecuteAsync(async () => {
      var { url, content } = req.getApiCallParams(this.baseUrl);

      var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

      return resp.data as ICodeGenerator;
    });
  }

  updateCodeGenerateAsync(req: CodeGenerateUpdationRq): Promise<ICodeGenerator> {

    return this.safelyExecuteAsync(async () => {
      var { url, content } = req.getApiCallParams(this.baseUrl);

      var resp = await axios.default.put(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

      return resp.data as ICodeGenerator;
    });
  }

  updateCodeGeneratorStatusAsync(req: CodeGeneratorStatusUpdationRq): Promise<ICodeGenerator> {
    return this.safelyExecuteAsync(async () => {
      var { url, content } = req.getApiCallParams(this.baseUrl);

      var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

      return resp.data as ICodeGenerator;
    });
  }

  fetchCodeGenerateAsync(req: CodeGenerateFetchRq): Promise<ICodeGenerator> {

    return this.safelyExecuteAsync(async () => {
      var { url } = req.getApiCallParams(this.baseUrl);

      var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

      return resp.data as ICodeGenerator;
    });
  }

  deleteCodeGenerateAsync(req: CodeGenerateDeletionRq): Promise<void> {

    return this.safelyExecuteAsync(async () => {
      var { url } = req.getApiCallParams(this.baseUrl);

      await axios.default.delete(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
    });
  }


  listCodeGenerateAsync(req: CodeGenerateListingRq): Promise<Array<ICodeGenerator>> {

    return this.safelyExecuteAsync(async () => {
      var { url } = req.getApiCallParams(this.baseUrl);

      const test = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
      return test.data;

    });
  }

}