import { IContentService } from "../interfaces/IContentService";
import { IGeneralSettingsState } from "../interfaces/IGeneralSettingsState";
import { ISettingsProvider } from "../interfaces/ISettingsProvider";
import { IGeneralSettings } from "../models/ISettings";

export class GeneralSettingsState implements IGeneralSettingsState {

    private generalSettings: IGeneralSettings | null = null;
    private readonly generalSettingsKey: string = "general-settings";
    private readonly faviconEl: HTMLLinkElement = document.getElementById("favicon") as HTMLLinkElement;

    constructor(private settingsProvider: ISettingsProvider, private contentService: IContentService) {
        if (!!localStorage.getItem("lmc-auth") && !!localStorage.getItem(this.generalSettingsKey)) {
            this.getGeneralSettings().then(() => {
                document.title = this?.generalSettings?.titleText || "LMC | CRM";
                this.faviconEl.href = this?.generalSettings?.companyLogoFavIcon || "/favicon.ico";
            })
        }
    }

    public updateGeneralSettings(settings: IGeneralSettings): void {
        if (!!settings) {
            this.generalSettings = settings;
            localStorage.setItem(this.generalSettingsKey, JSON.stringify(this.generalSettings));
            document.dispatchEvent(new CustomEvent("general-settings", { detail: this.generalSettings }));
            document.title = this?.generalSettings?.titleText || "LMC | CRM";
            this.faviconEl.href = this?.generalSettings?.companyLogoFavIcon || "/favicon.ico";
        }
    }

    public async getGeneralSettings(): Promise<IGeneralSettings | null> {
        if (!this.generalSettings) {
            const generalSettings: string | null = localStorage.getItem(this.generalSettingsKey);
            this.generalSettings = !!generalSettings ? JSON.parse(generalSettings) : null;
        }
        if (!this.generalSettings) {
            this.generalSettings = await this.settingsProvider.getGeneralSettings();
        }
        const authUser: string | null = localStorage.getItem("lmc-auth");
        if (!!authUser) {
            const authInfo = !!authUser ? JSON.parse(authUser) : null;
            if (!!authInfo) {
                const [logo, faviconLogo] = await Promise.all([
                    this.contentService.getImageAsync(authInfo?.orgId as string, authInfo?.orgId as string),
                    this.contentService.getImageAsync(authInfo?.orgId as string, "favicon-" + authInfo?.orgId as string)
                ]);
                this.generalSettings.companyLogoFavIcon = faviconLogo;
                this.generalSettings.companyLogoUrl = logo;
                this.updateGeneralSettings(this.generalSettings);
            }
        }
        return this.generalSettings;
    }

    public clear(): void {
        localStorage.removeItem(this.generalSettingsKey);
        this.generalSettings = null;
        document.dispatchEvent(new CustomEvent("general-settings", { detail: this.generalSettings }));
        document.title = "LMC | CRM";
        this.faviconEl.href = "/favicon.ico";
    }

}