import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap";
import { User, HelpCircle } from "react-feather";

import Avatar from "react-avatar";

import defaultAvatar from "../../../assets/images/empty-pic.png";
import { DependencyTokens } from "../../../settings";
import { ContainerContext } from "../../../contexts/containerContext";
import { DependencyContainer } from "tsyringe";
import { IAuthInfo, IAuthState } from "../../../interfaces/IAuthState";
import { IGeneralSettingsState } from "../../../interfaces/IGeneralSettingsState";

export default function UserDropdown() {

  const container = useContext(ContainerContext) as DependencyContainer;
  const authState = container.resolve<IAuthState>(DependencyTokens.defaultAuthState);
  const generalSettingsState = container.resolve<IGeneralSettingsState>(DependencyTokens.defaultGeneralSettingsState);

  const [authInfo, setAuthInfo] = useState<IAuthInfo>();

  const authHandler = useRef<any>((_authInfo: IAuthInfo) => { setAuthInfo(_authInfo); });

  useEffect(() => {
    let unsubscribe = authState.onAuthChanged(authHandler.current);
    return () => unsubscribe();
  }, []);

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name font-weight-bold">
            {[
              authInfo?.user?.attributes.identity.firstName,
              authInfo?.user?.attributes.identity.lastName ?? "",
            ]
              .join(" ")
              .trim()}
          </span>
          <span className="user-status">
            {authInfo?.user?.attributes.identity.position}
          </span>
        </div>
        {
          !!authInfo?.user?.attributes.identity.profilePictureUrl ?
            <> <Avatar src={authInfo?.user?.attributes.identity.profilePictureUrl} round={true} size="40" />
            </>
            :
            <>
              <Avatar
                style={{
                  marginLeft: "2px",
                  marginRight: "2px",
                }}
                size="40"
                name={[
                  authInfo?.user?.attributes.identity?.firstName ?? "",
                  authInfo?.user?.attributes.identity?.lastName ?? "",
                ]
                  .join(" ")
                  .trim()} round={true} />
            </>
        }
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem tag={Link} to="/profile">
          <User size={14} className="mr-75" />
          <span className="align-middle">Profile</span>
        </DropdownItem>
        {/* <DropdownItem tag={Link} to="#" onClick={e => e.preventDefault()}>
          <Mail size={14} className="mr-75" />
          <span className="align-middle">Inbox</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="#" onClick={e => e.preventDefault()}>
          <CheckSquare size={14} className="mr-75" />
          <span className="align-middle">Tasks</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="#" onClick={e => e.preventDefault()}>
          <MessageSquare size={14} className="mr-75" />
          <span className="align-middle">Chats</span>
        </DropdownItem> */}
        {/* <DropdownItem tag={Link} to="/settings">
          <Settings size={14} className="mr-75" />
          <span className="align-middle">Settings</span>
        </DropdownItem> */}
        <DropdownItem
          tag={Link}
          to="/login"
          onClick={() => {
            authState.clear();
            generalSettingsState.clear();
          }}
        >
          <HelpCircle size={14} className="mr-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
