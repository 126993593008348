import "reflect-metadata";
import { LicenseManager } from "ag-grid-enterprise";
import { ContainerFactory } from "./containerFactory";
import { ContainerContext } from "./contexts/containerContext";
import Router from "./routes";
import React, { useLayoutEffect, useRef, useState } from "react";
import { Spinner } from "reactstrap";
import { useEffect } from "react";
import { IToast, ToastState } from "./impl/ToastState";
import LoadingOverlay from "react-loading-overlay";
import { LoaderState } from "./impl/LoaderState";
import { ToastContainer, toast, ToastOptions} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

LicenseManager.setLicenseKey(
  "For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-5_September_2021_[v2]_MTYzMDc5NjQwMDAwMA==6fb65d79e49482f064d73fcb14bc7f4b"
);

function App() {
  const container = ContainerFactory.getContainer();
  const [loaderActive, setLoaderActive] = useState<boolean>(false);

  var toastHandler = useRef<(toast: IToast) => void>((_toast) => {
    var options: ToastOptions = {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };

    switch (_toast.type) {
      case "error":
      case "danger":
        toast.error(_toast.message, options);
        break;
      case "info":
      case "success":
        toast.info(_toast.message, options);
        break;
    }
  });

  var loaderHandler = useRef<(isActive: boolean) => void>((isActive) => {
    setLoaderActive(isActive);
  });

  useEffect(() => {
    ToastState.instance.addListener(toastHandler.current);
    LoaderState.instance.addListener(loaderHandler.current);
  }, []);

  useLayoutEffect(() => {
    if (loaderActive) {
      document.body.style.overflowY = "hidden";
      document.body.style.overflowX = "hidden";
      document.body.style.height = "auto";
    }
    if (!loaderActive) {
      document.body.style.overflowY = "visible";
      document.body.style.overflowX = "hidden";
      document.body.style.height = "auto";
    }
  }, [loaderActive]);

  return (
      <LoadingOverlay
        active={loaderActive}
        className={!!loaderActive ? "overlay" : ""}
        spinner={
          <Spinner
            color="primary"
            style={{
              width: "3rem",
              height: "3rem",
              position: "fixed",
              top: "50%",
              left: "50%",
            }}
          />
        }
      >
        <ContainerContext.Provider value={container}>
          <Router />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </ContainerContext.Provider>
      </LoadingOverlay>
  );
}
export default App;
