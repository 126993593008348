import { BankRuleDeletionRq, BankRulesListingRq, BankRuleStatusUpdationRq, BankRuleVersionActivationRq, BankRuleVersionsFetchRq } from './../models/bank-rules-request';
import { IBankRule, ICondition } from './../models/BankRules';
import { IBankRulesProvider } from './../interfaces/IBankRulesProvider';
import { PagedList } from "../models/pagedList";
import { ServiceBase } from "./ServiceBase";
import * as axios from "axios";
import { IAuthState } from "../interfaces/IAuthState";
import { v4 } from 'uuid';
import { BankRuleCreationRq, BankRuleFetchRq, BankRuleUpdationRq } from '../models/bank-rules-request';

export class BankRulesProvider extends ServiceBase implements IBankRulesProvider {

    constructor(baseUrl: string,  tenantId: string,  authState: IAuthState) {
        super(baseUrl, tenantId, authState);
    }

    createAsync(req: BankRuleCreationRq): Promise<IBankRule> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);
            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
            return resp.data as IBankRule;
        });
    }

    updateAsync(req: BankRuleUpdationRq): Promise<IBankRule> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);
            var resp = await axios.default.put(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
            return resp.data as IBankRule;
        });
    }

    updateStatusAsync(req: BankRuleStatusUpdationRq): Promise<IBankRule>{
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);
            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
            return resp.data as IBankRule;
        });
    }

    fetchAsync(req: BankRuleFetchRq): Promise<IBankRule> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);
            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
            return resp.data as IBankRule;
        });
    }

    fetchVersionsAsync(req: BankRuleVersionsFetchRq): Promise<IBankRule[]>{
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);
            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
            return resp.data as IBankRule[];
        });
    }

    deleteAsync(req: BankRuleDeletionRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);
            await axios.default.delete(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    activateRuleVersionAsync(req: BankRuleVersionActivationRq): Promise<void>{
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);
            await axios.default.post(url, null, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    listAsync(req: BankRulesListingRq): Promise<PagedList<IBankRule>> {

        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);
            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
            return {
                items: (resp.data.items ?? []).map((x: any) => Object.assign({}, x) as IBankRule),
                pagingInfo: {
                    pageNum: resp.data.pagingInfo.pageNumber,
                    pageSize: resp.data.pagingInfo.pageSize,
                    totalItems: resp.data.pagingInfo.totalItems
                }
            }
        });
    }
}