import { ServiceBase } from "./ServiceBase";
import * as axios from "axios";
import { IAuthState } from "../interfaces/IAuthState";
import { IDashboardProvider } from '../interfaces/IDashboardProvider';
import { DashboardLeadPerNWUserFetchRq, DashboardLeadPerProductFetchRq, DashboardLeadPerSalesUserFetchRq, DashboardLeadStatFetchRq } from '../models/dashboard-request';

export class DashboardProvider extends ServiceBase implements IDashboardProvider {


 constructor(baseUrl: string,  tenantId: string,  authState: IAuthState) {
    super(baseUrl, tenantId, authState);
}


fetchDashboardLeadsStatAsync(req: DashboardLeadStatFetchRq): Promise<any> {

        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as any;
        });
    }


    fetchDashboardLeadsPerSalesUserAsync(req: DashboardLeadPerSalesUserFetchRq): Promise<any> {

    return this.safelyExecuteAsync(async () => {
        var { url } = req.getApiCallParams(this.baseUrl);

        var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

        return resp.data as any;
    });
}


fetchDashboardLeadsPerProductAsync(req: DashboardLeadPerProductFetchRq): Promise<any> {

    return this.safelyExecuteAsync(async () => {
        var { url } = req.getApiCallParams(this.baseUrl);

        var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

        return resp.data as any;
    });
}

fetchDashboardLeadsPerNWUserAsync(req: DashboardLeadPerNWUserFetchRq): Promise<any> {

    return this.safelyExecuteAsync(async () => {
        var { url } = req.getApiCallParams(this.baseUrl);

        var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

        return resp.data as any;
    });
}

}
