
export class DependencyTokens {
    public static readonly defaultUsersProvider: string = "default-users-provider";
    public static readonly defaultDSAdapter: string = "default-ds-adapter";
    public static readonly defaultEntityProvider: string = "default-entity-provider";
    public static readonly defaultSettingsProvider: string = "default-settings-provider";
    public static readonly defaultContentService: string = "default-content-service";
    public static readonly defaultAuthProvider: string = "default-auth-provider";
    public static readonly defaultAuthState: string = "default-auth-state";
    public static readonly defaultGeneralSettingsState: string = "default-general-settings-state";
    public static readonly defaultLeadsProvider: string = "default-leads-provider";
    public static readonly defaultNotificationProvider: string = "default-notification-provider";
    public static readonly defaultCalendarProvider: string = "default-calendar-provider";
    public static readonly defaultNotificationState: string = "default-notification-state";
    public static readonly defaultBanksProvider: string = "default-banks-provider";
    public static readonly defaultBankRmsProvider: string = "default-bank-rms-provider";
    public static readonly defaultEiborRatesProvider: string = "default-eibor-rates-provider";
    public static readonly defaultApprovedDevelopersProvider: string = "default-approved-developer-provider";
    public static readonly defaultBankRulesProvider: string = "default-bank-rules-provider";
    public static readonly defaultBankOfferProvider: string = "default-bank-offer-provider";
    public static readonly defaultDashboardProvider: string = "default-dashboard-provider";
    public static readonly defaultQuotationProvider: string = "default-quotation-provider";
    public static readonly defaultQuoteProvider: string = "default-quote-provider";
    public static readonly defaultCodegeneratorProvider: string = "default-code-generator-provider";
}

export class ApiRouteKeys {
    public static readonly InternalUsers: string = "internalusers";
    public static readonly NetworkPartners: string = "nwpartners";
    public static readonly ReferralPartners: string = "refpartners";
    public static readonly WhiteLabelPartners: string = "wlpartners";
    public static readonly CrossSellPartners: string = "cspartners";
    public static readonly Users: string = "users";
    public static readonly Specialists: string = "specialists";
    public static readonly UsersInScope: string = "usersInScope";
    public static readonly Stats: string = "stats";
    public static readonly Content: string = "content";
    public static readonly ProfilePics: string = "profile-pics";
    public static readonly Logos: string = "logos";
    public static readonly Auth: string = "auth";
    public static readonly ActivateAccount: string = "activate-account";
    public static readonly RecoverAccount: string = "recover-account";
    public static readonly SetPassword: string = "set-password";
    public static readonly UpdatePassword: string = "update-password";
    public static readonly ExtendToken: string = "extend-token";
    public static readonly ValidateToken: string = "validate-token";
    public static readonly Login: string = "login";
    public static readonly Tenants: string = "tenants";
    public static readonly Settings: string = "settings";
    public static readonly Type: string = "type";
    public static readonly Suspend: string = "suspend";
    public static readonly Activate: string = "activate";
    public static readonly ResendActivationLink: string = "resend-activation-link";
    public static readonly Bulk: string = "bulk";
    public static readonly Delete: string = "delete";
    public static readonly LeadSources: string = "lead-sources";
    public static readonly Leads: string = "leads";
    public static readonly Audits: string = "audits";
    public static readonly Notifications: string = "notifications";
    public static readonly Read: string = "read";
    public static readonly Reminders: string = "reminders";
    public static readonly Status: string = "status";
    public static readonly Notification: string = "notification";
    public static readonly AssignedUsers: string = "assigned-users";
    public static readonly LeadSource: string = "lead-source";
    public static readonly ProductDetails: string = "product-details";
    public static readonly Comments: string = "comments";
    public static readonly CallSummary: string = "call-summary";
    public static readonly Email: string = "email";
    public static readonly Contact: string = "contact";
    public static readonly Restore: string = "restore";
    public static readonly NextAction: string = "next-action";
    public static readonly UpdateQuotationAssignUsers: string = "update-assigned-users";
    public static readonly ClientRM: string = "client-rm";
    public static readonly Banks: string = "banks";
    public static readonly RMs: string = "bank-rms";
    public static readonly EiborRates: string = "eibore-rates";
    public static readonly ApprovedDevelopers: string = "approved-developers";
    public static readonly BankRules: string = "bank-rules";
    public static readonly Departments: string = "departments";
    public static readonly BankOffer: string = "bank-offers";
    public static readonly VeridySMTP: string = "verify-smtp";
    public static readonly OffersQuery: string = "offers-query";
    public static readonly Quote: string = "quote";
    public static readonly CodeGenerate: string = "code-generate";
    public static readonly Quotations: string = "quotations";
    public static readonly QuotationCustomers: string = "quotation-customers";
    public static readonly CustomerQuotations: string = "customer-quotations";
    public static readonly UpdateStatus: string = "update-status";
    public static readonly Exist: string = "exist";
    public static readonly ClientRms: string = "client-rms";
    public static readonly Notify: string = "notify";
}

export class QueryParams {
    public static readonly PageNum: string = "pageNum";
    public static readonly PageSize: string = "pageSize";
    public static readonly FetchRead: string = "fetchRead";
    public static readonly Name: string = "name";
    public static readonly FilterTerm: string = "filterTerm";
    public static readonly ProductIds: string = "productIds";
    public static readonly CategoryIds: string = "categoryIds";
    public static readonly FetchAll: string = "fetchAll";
    public static readonly type: string = "type";
    public static readonly tag: string = "tag";
    public static readonly FromTimestamp: string = "fromTimestamp";
    public static readonly ToTimestamp: string = "toTimestamp";
    public static readonly EpochStart: string = "epochStart";
    public static readonly EpochEnd: string = "epochEnd";
    public static readonly Resourceid: string = "resourceId";
    public static readonly ContactEmailId: string = "contactEmailId";
    public static readonly EmailId: string = "emailId";
    public static readonly BankId: string = "bankId";
    public static readonly BankIds: string = "bankIds";
    public static readonly ApprovedDeveloperId: string = "approvedDeveloperId";
    public static readonly IncludeInactive: string = "includeInactive";
    public static readonly LeadPerUser: string = "leadperuser";
    public static readonly AgGridModel: string = "agGridModel";
    public static readonly LeadEmailId: string = "leadEmailId";
    public static readonly PopulateCreatorInfo: string = "populateCreatorInfo";
    public static readonly skipContent: string = "skipContent";
}

export class Features {
    public static readonly Dashboard: string = "dashboard";
    public static readonly LeadManagement: string = "leads_management";
    public static readonly LeadSource: string = "leads_source";
    public static readonly Banks: string = "banks";
    public static readonly BankOffer: string = "banks_offer";
    public static readonly BankRules: string = "banks_rules";
    public static readonly BankApprovedDeveloper: string = "approvedDeveloper";
    public static readonly BankEiboreRate: string = "eiboreRate";
    public static readonly BankRM: string = "bank_rm";
    public static readonly BTCalculator: string = "bt_calculator";
    public static readonly EMICalculator: string = "emi_calculator";
    public static readonly QuoteGeneration: string = "generate_quote";
    public static readonly NewQuoteGeneration: string = "new_quote";
    public static readonly Reports: string = "report";
    public static readonly EmiCalculator: string = "emi_calculator";
    public static readonly ReferralPartners: string = "users_referral_partners";
    public static readonly CrossSellPartners: string = "users_cross_sell_partners";
    public static readonly NetworkPartners: string = "users_network_partners";
    public static readonly WLPartners: string = "users_white_labelling";
    public static readonly InternalUsers: string = "users_internal_users";
    public static readonly UsersTab: string = "users_tab";
    public static readonly Settings: string = "settings";
    public static readonly Calendar: string = "calendar";
}

export class Capabilities {
    public static readonly Export: string = "export";
    public static readonly ExportLeads: string = "export_leads";
    public static readonly ImportLeads: string = "import_leads";
    public static readonly ExportGenerateQuote: string = "export_generate_quote";

    public static readonly Add: string = "add";
    public static readonly Edit: string = "edit";
    public static readonly View: string = "view";
    public static readonly Delete: string = "delete";
    public static readonly BulkDelete: string = "delete_tab";
    public static readonly AssignTo: string = "assign_to";

    public static readonly AddBank: string = "add_bank";
    public static readonly EditBank: string = "edit_bank";
    public static readonly DeleteBank: string = "delete_bank";
    public static readonly ExportBanks: string = "export_banks";

    public static readonly AddBankRule: string = "add_bank_rule";
    public static readonly EditBankRule: string = "edit_bank_rule";
    public static readonly DeleteBankRule: string = "delete_bank_rule";
    public static readonly ExportBankRules: string = "export_bank_rules";

    public static readonly AddBankOffer: string = "add_bank_offer";
    public static readonly EditBankOffer: string = "edit_bank_offer";
    public static readonly DeleteBankOffer: string = "delete_bank_offer";
    public static readonly ExportBankOffers: string = "export_bank_offers";
    public static readonly SendOfferDetails: string = "send_offer_details";


    public static readonly AddBankRM: string = "add_bank_rm";
    public static readonly EditBankRM: string = "edit_bank_rm";
    public static readonly DeleteBankRM: string = "delete_bank_rm";
    public static readonly ExportBankRMs: string = "export_bank_rms";

    public static readonly AddApprovedDeveloper: string = "add_approved_developer";
    public static readonly EditApprovedDeveloper: string = "edit_approved_developer";
    public static readonly DeleteApprovedDeveloper: string = "delete_approved_developer";
    public static readonly ExportApprovedDevelopers: string = "export_approved_developers";

    public static readonly AddEiboreRate: string = "add_eibor_rate";
    public static readonly EditEiboreRate: string = "edit_eibor_rate";
    public static readonly DeleteEiboreRate: string = "delete_eibor_rate";
    public static readonly ExportEiboreRates: string = "export_eibor_rates";
}

export const DateFormat = "DD-MM-YYYY";

export const DefaultCurrency = "AED";

export const DateControlFormat = "YYYY-MM-DD";

export const EmailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/;

export const UaeNationality = "united arab emirates";

export const LeadImportFields = {
    title: "Title",
    firstName: "First Name",
    lastName: "Last Name",
    emailId: "Email Id",
    dob: "DOB (YYYY-MM-DD)",
    mobileNo: "Mobile No",
    companyName: "Company Name",
    nationality: "Nationality",
    residentStatus: "Resident Status",
    salariedOrSelf: "Occupation Type",
    products: "Product",
    users: "Sales Owner Email Id",
    status: "Status",
    statusComment: "Status Comment",
    sourceCategory: "Source",
    sourceSubCategory: "Source Sub Category",
    sourcePersonName: "Source Name",
    clientRMemailIds: "Client RM Email Id"
}