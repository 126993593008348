import { IAuthState } from "../interfaces/IAuthState";
import { IBankRMProvider } from "../interfaces/IBankRmProvider"
import { IBankRM } from "../models/bankRm";
import { BankRMBulkDeletionRq, BankRMCreationRq, BankRMDeletionRq, BankRMFetchRq, BankRMStatusUpdationRq, BankRMUpdationRq, ListBankRmsRq } from "../models/bankRM-requests";
import { PagedList } from "../models/pagedList";
import { ServiceBase } from "./ServiceBase"
import * as axios from "axios";

export class BankRmProvider extends ServiceBase implements IBankRMProvider {

    constructor(baseUrl: string, tenantId: string, authState: IAuthState) {
        super(baseUrl, tenantId, authState);
    }

    createBankRmAsync(req: BankRMCreationRq): Promise<IBankRM> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IBankRM;
        });
    }

    updateBankRmAsync(req: BankRMUpdationRq): Promise<IBankRM> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.put(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IBankRM;
        });
    }

    updateBankRmStatusAsync(req: BankRMStatusUpdationRq): Promise<IBankRM>{
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IBankRM;
        });
    }

    deleteBankRmAsync(req: BankRMDeletionRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            await axios.default.delete(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    fetchBankRmAsync(req: BankRMFetchRq): Promise<IBankRM> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IBankRM;
        });
    }

    listBankRmsAsync(req: ListBankRmsRq): Promise<PagedList<IBankRM>> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return {
                items: (resp.data.items ?? []).map((x: any) => Object.assign({}, x) as IBankRM),
                pagingInfo: {
                    pageNum: resp.data.pagingInfo.pageNumber,
                    pageSize: resp.data.pagingInfo.pageSize,
                    totalItems: resp.data.pagingInfo.totalItems
                }
            }
        });
    }
}