import { IDatasource, IGetRowsParams, IServerSideDatasource, IServerSideGetRowsParams, IServerSideGetRowsRequest } from "ag-grid-community";
import { cloneDeep } from "lodash";
import { IDatasourceAdapter } from "../interfaces/IDataSourceAdapter";
import { AgFilterModel, AgSortModel } from "../models/ag-grid-models";
import { IBank } from "../models/bank";
import { PagedList } from "../models/pagedList";

export class DataSourceAdapter implements IDatasourceAdapter {
    private additionalParams: any = {};
    private filterItems: AgFilterModel = {};
    private sortItems: AgSortModel[] = [];
    constructor(private pageSize: number = 10, private filterText: string | null = null) {

    }

    setPageSize(pageSize: number): void {
        this.pageSize = pageSize;
    }

    setFilterText(filterText: string | null): void {
        this.filterText = filterText;
    }

    setAdditionalParam(name: string, value: any): void {
        this.additionalParams[name] = value;
    }

    getAdapter(apiCallBack: (pageNum: number, pageSize: number, filterText: string | null, additionalParams: any, params: IGetRowsParams) => Promise<PagedList<any>>): IDatasource {
        var that = this;

        return {
            getRows(params: IGetRowsParams): void {
                var pageNum = Math.floor(params.startRow / that.pageSize) + 1;
                apiCallBack(pageNum, that.pageSize, that.filterText, that.additionalParams, params)
                    .then(
                        resp => {
                            setTimeout(() => {
                                params.successCallback(resp.items, resp.pagingInfo?.totalItems)
                            }, 100)

                        },
                        () => params.failCallback()
                    );
            }
        }
    }

    getServerAdapter(apiCallBack: (request: IServerSideGetRowsRequest, pageNum: number, pageSize: number) => Promise<PagedList<any>>): IServerSideDatasource {
        var that = this;
        const datasource: IServerSideDatasource = {
            async getRows(params: IServerSideGetRowsParams) {
                const request: IServerSideGetRowsRequest = cloneDeep(params.request);
                if (!!that.filterText && !!that.filterText.length) {
                    request.filterModel = request.filterModel || {};
                    if (!request.filterModel["bankName"]) {
                        request.filterModel["bankName"] = {
                            filterType: "text",
                            type: "contains",
                            filter: that.filterText
                        }
                    };
                    const additionalKeys = Object.keys(that.additionalParams || {});
                    additionalKeys.forEach((key: string) => {
                        if (!request.filterModel[key]) {
                            request.filterModel[key] = {
                                filterType: "text",
                                type: "contains",
                                filter: that.additionalParams[key]
                            }
                        };
                    });
                }
                var pageNum = Math.floor(request.startRow / that.pageSize) + 1;
                const response: PagedList<IBank> = await apiCallBack(request, pageNum, that.pageSize);
                params.successCallback(response.items, response?.pagingInfo?.totalItems as number)
            }
        };
        return datasource;
    }
}