import { INotificationProvider } from './../interfaces/INotificationProvider';
import { INotification } from './../models/notification';
import { PagedList } from "../models/pagedList";
import { ServiceBase } from "./ServiceBase";
import * as axios from "axios";
import { DeleteNotificationRq, NotificationAllDeletionRq, NotificationCreationRQ, NotificationFetchRq, NotificationListingRq, NotificationUpdationRQ } from '../models/notification-request';
import { IAuthState } from '../interfaces/IAuthState';

export class NotificationProvider extends ServiceBase implements INotificationProvider {
    constructor(baseUrl: string, tenantId: string, authState: IAuthState) {
        super(baseUrl, tenantId, authState);
    }
    
    createNotificationAsync(req: NotificationCreationRQ): Promise<INotification> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content);

            return resp.data as INotification;
        });
    }

    updateNotificationAsync(req: NotificationUpdationRQ): Promise<INotification> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.put(url, content);

            return resp.data as INotification;
        });
    }

    fetchNotificationAsync(req: NotificationFetchRq): Promise<INotification> {
        return this.safelyExecuteAsync(async () => {
            var {url} = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url);

            return resp.data as INotification;
        });
    }

    deleteAllNotificationAsync(req: NotificationAllDeletionRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url} = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url);
        });
    }

    deleteUserNotificationAsync(req: DeleteNotificationRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url} = req.getApiCallParams(this.baseUrl);

            await axios.default.delete(url);
        });
    }
    // private getPagingInfo(respData: any): PagingInfo {
    //     return {
    //         pageNum: respData.pagingInfo.pageNumber,
    //         pageSize: respData.pagingInfo.pageSize,
    //         totalItems: respData.pagingInfo.totalItems
    //     }
    // }




    async listNotificationAsync(req: NotificationListingRq): Promise<PagedList<INotification>> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);
            var resp = await axios.default.get(url, content);

            return {
                items: (resp.data.items ?? []).map((x: any) => Object.assign({ }, x) as INotification),
                pagingInfo: {
                    pageNum: resp.data.paginationInfo.pageNumber,
                    pageSize: resp.data.paginationInfo.pageSize,
                    totalItems: resp.data.paginationInfo.totalItems
                }
            }
        });
    }
}