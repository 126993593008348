import { DocumentType, EmploymentStatus, LeadStatusType, NationalityStatus, ResidentStatus, SettingsType } from "./enums";

export interface ISettingsBase {
    id: string;
    type: SettingsType;
    tags: string[];
    createDateTimeStamp: number,
    updateDateTimeStamp: number,
}

export interface ISettings extends ISettingsBase {
    attributes: any;
}

export interface IGeneralSettings extends ISettingsBase {
    companyName: string,
    companyLogoUrl: string,
    companyLogoFavIcon: string,
    address: string,
    city: string,
    country: string,
    website: string,
    titleText: string,
    footerText: string,
    timeZone: string,
    masterEmailId: string
    emailId?: string,
    trnNu?: string,
    phoneNo?: number
}

export interface ILeadsSettings extends ISettingsBase {

}

export interface ISmptSetttings extends ISettingsBase {
    smtp: string,
    server: string,
    port: number,
    username: string,
    password: string,
    from: string
}

export interface INotificationSettings extends ISettingsBase {
    userRoles: string[],
    expiryDuration: number,
    expiryUnit: string,
    email: boolean,
    system: boolean,

}


export interface INotificationDupSettings extends ISettingsBase {
    userRolesDup: string[],
}

export interface IStatusSettings extends ISettingsBase {
    label: string,
    color: string,
    rank: number,
    name: string
    count?: number,
    leadStatusType?: LeadStatusType
}

export interface IDashboardSettings extends ISettingsBase {
    title: string,
    leads: boolean,
    sales: boolean,
    users: boolean,
    reminders: boolean,
    products_leads_data: boolean,
    newtwork_partner_data: boolean,
    isMaster: boolean
}

export interface IDeveloperSettings extends ISettingsBase {
    label: string,
    logoUrl: string
}

export interface IProductServiceSettings extends ISettingsBase {
    label: string,
    rank: number
}
export interface EiBoreSettings extends ISettingsBase {
    label: string,
}

export interface EiBoreMonthsSettings extends ISettingsBase {
    months: number;
}

export interface ILeadSourceSettings extends ISettingsBase {
    leadLabel: string,
    leadPosition: string
}

export interface IRoleSettings extends ISettingsBase {
    roleName: string,
    features: any,
    forAdmin: boolean
}
export interface IProjectSettings extends ISettingsBase {
    label: string,
    developer: any
}
export interface IBankOfferSettings extends ISettingsBase {
    title?: string,
    residentStatus?: ResidentStatus,
    salaryType?: EmploymentStatus,
    documentType?: string,
    docType?: DocumentType,
    nationalityStatus?: NationalityStatus
}

export interface ISMTPSettings {
    host?: string,
    port?: number,
    username?: string,
    password?: string
}



export class SettingsTransform {

    public static toSettings(settings: any): ISettings {
        if (!(!!settings))
            throw "Settings must be not null";
        let nonAttrkeys = ["id", "tags", "type", "createDateTimeStamp", "updateDateTimeStamp"];

        let attr = Object.assign({});
        Object.keys(settings).forEach((key) => {
            if (!nonAttrkeys.includes(key))
                attr[key] = settings[key];
        })

        return {
            id: settings.id,
            tags: settings.tags,
            type: settings.type,
            createDateTimeStamp: settings.createDateTimeStamp,
            updateDateTimeStamp: settings.updateDateTimeStamp,
            attributes: attr
        }
    }


    public static toSettingsBase(settings: ISettings): ISettingsBase {
        return Object.assign({}, {
            id: settings?.id,
            type: settings?.type,
            tags: settings?.tags,
            createDateTimeStamp: settings?.createDateTimeStamp,
            updateDateTimeStamp: settings?.updateDateTimeStamp,
        })
    }

    public static toGeneralSettings(settings: ISettings): IGeneralSettings {
        if (!(!!settings))
            return {} as IGeneralSettings;

        return Object.assign({}, this.toSettingsBase(settings), {
            companyName: settings?.attributes["companyName"],
            companyLogoUrl: settings?.attributes["companyLogoUrl"],
            companyLogoFavIcon: settings?.attributes["companyLogoFavIcon"],
            address: settings?.attributes["address"],
            city: settings?.attributes["city"],
            country: settings?.attributes["country"],
            website: settings?.attributes["website"],
            titleText: settings?.attributes["titleText"],
            footerText: settings?.attributes["footerText"],
            timeZone: settings?.attributes["timeZone"],
            masterEmailId: settings?.attributes["masterEmailId"],
            trnNu: settings?.attributes["trnNu"],
            emailId: settings?.attributes["emailId"],
            phoneNo: settings?.attributes["phoneNo"]
        })
    }

    public static toSmtpSettings(settings: ISettings): ISmptSetttings {
        return Object.assign({}, this.toSettingsBase(settings), {
            smtp: settings?.attributes["smtp"],
            server: settings?.attributes["server"],
            port: settings?.attributes["port"],
            username: settings?.attributes["username"],
            password: settings?.attributes["password"],
            from: settings?.attributes["from"],

        })
    }


    public static toNotificationSettings(settings: ISettings): INotificationSettings {
        return Object.assign({}, this.toSettingsBase(settings), {
            userRoles: settings?.attributes["userRoles"],
            expiryDuration: settings?.attributes["expiryDuration"],
            expiryUnit: settings?.attributes["expiryUnit"],
            email: settings?.attributes["email"],
            system: settings?.attributes["system"]
        })
    }
    public static toNotificationDupSettings(settings: ISettings): INotificationDupSettings {
        return Object.assign({}, this.toSettingsBase(settings), {
            userRolesDup: settings?.attributes["userRolesDup"],

        })
    }
    public static toProjectSettings(settings: ISettings): IProjectSettings {
        return Object.assign({}, this.toSettingsBase(settings), {
            label: settings?.attributes["label"],
            developer: settings?.attributes["developer"],
        })
    }
    public static toStatusSettings(settings: ISettings): IStatusSettings {
        return Object.assign({}, this.toSettingsBase(settings), {
            label: settings?.attributes["label"],
            color: settings?.attributes["color"],
            rank: settings?.attributes["rank"],
            name: settings?.attributes["name"],
            leadStatusType: settings?.attributes["leadStatusType"]
        });
    }

    public static toDashboardSettings(settings: ISettings): IDashboardSettings {
        return Object.assign({}, this.toSettingsBase(settings), {
            title: settings?.attributes["title"],
            leads: settings?.attributes["leads"],
            sales: settings?.attributes["sales"],
            users: settings?.attributes["users"],
            reminders: settings?.attributes["reminders"],
            products_leads_data: settings?.attributes["products_leads_data"],
            newtwork_partner_data: settings?.attributes["newtwork_partner_data"],
            isMaster: settings?.attributes["isMaster"],
        });
    }

    public static toDeveloperSettings(settings: ISettings): IDeveloperSettings {
        return Object.assign({}, this.toSettingsBase(settings), {
            label: settings?.attributes["label"],
            logoUrl: settings?.attributes["logoUrl"]
        });
    }

    public static toLeadsSourceSettings(settings: ISettings) {
        return Object.assign({}, this.toSettingsBase(settings), {
            leadLabel: settings?.attributes["leadLabel"],
            leadPosition: settings?.attributes["leadPosition"]
        });
    }

    public static toRoleSettings(settings: ISettings) {
        return Object.assign({}, this.toSettingsBase(settings), {
            roleName: settings?.attributes["roleName"],
            usersCount: settings?.attributes["usersCount"],
            features: settings?.attributes["features"],
            forAdmin: settings?.attributes["forAdmin"] ?? false
        });
    }

    public static toProductServiceSettings(settings: ISettings): IProductServiceSettings {
        return Object.assign({}, this.toSettingsBase(settings), {
            label: settings?.attributes["label"],
            rank: settings?.attributes["rank"]
        });
    }

    public static toEiBoreSettings(settings: ISettings): EiBoreSettings {
        return Object.assign({}, this.toSettingsBase(settings), {
            label: settings?.attributes["label"]
        });
    }

    public static toEiBoreMonthsSettings(settings: ISettings): EiBoreMonthsSettings {
        return Object.assign({}, this.toSettingsBase(settings), {
            months: settings?.attributes["months"],
        });
    }

    public static toBankOfferSettings(settings: ISettings) {
        return Object.assign({}, this.toSettingsBase(settings), {
            title: settings?.attributes["title"]

        });
    }

    public static toBankDocumentSettings(settings: ISettings) {
        return Object.assign({}, this.toSettingsBase(settings), {
            residentStatus: settings?.attributes["residentStatus"],
            salaryType: settings?.attributes["salaryType"],
            documentType: settings?.attributes["documentType"],
            docType: settings?.attributes["docType"],
            nationalityStatus: settings?.attributes["nationalityStatus"]

        });
    }

}