import { LeadAuditCreationRq } from './../models/lead-requests';
import { ILeadProvider } from "../interfaces/ILeadProvider";
import { ILeadSource } from "../models/lead-source";
import { LeadSourceCreationRq, LeadSourceUpdationRq, LeadSourceListingRq, LeadSourceFetchRq, LeadSourceDeletionRq, LeadSourceBulkDeletionRq } from "../models/lead-source-requests";
import { PagedList } from "../models/pagedList";
import { ServiceBase } from "./ServiceBase";
import * as axios from "axios";
import { ILead, ILeadRecentAction } from "../models/lead";
import { LeadCreationRq, LeadFetchRq, LeadDeletionRq, LeadListingRq, LeadBulkDeletionRq, LeadProductDataUpdateRq, LeadStatusUpdationRq, AddCommentRq, AddCallSummaryRq, LeadContactUpdateRq, SendEmailRq, UpdateAssignedUsers, LeadRecentActionsReq, LeadRestorationRq, NextActionUpdateRq, LeadEmailVerificationRq, BulkLeadCreationRq, ClientRMUpdateRq, UpdateLeadSourceRq } from "../models/lead-requests";
import { IAuthState } from "../interfaces/IAuthState";

export class LeadProvider extends ServiceBase implements ILeadProvider {

    constructor(baseUrl: string, tenantId: string, authState: IAuthState) {
        super(baseUrl, tenantId, authState);
    }

    createLeadSourceAsync(req: LeadSourceCreationRq): Promise<ILeadSource> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as ILeadSource;
        });
    }

    updateLeadSourceAsync(req: LeadSourceUpdationRq): Promise<ILeadSource> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.put(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as ILeadSource;
        });
    }

    fetchLeadSourceAsync(req: LeadSourceFetchRq): Promise<ILeadSource> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as ILeadSource;
        });
    }

    deleteLeadSourceAsync(req: LeadSourceDeletionRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            await axios.default.delete(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    bulkDeleteLeadSourcesAsync(req: LeadSourceBulkDeletionRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    bulkDeleteLeadsAsync(req: LeadBulkDeletionRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    listLeadSourcesAsync(req: LeadSourceListingRq): Promise<PagedList<ILeadSource>> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return {
                items: (resp.data.items ?? []).map((x: any) => Object.assign({}, x) as ILeadSource),
                pagingInfo: {
                    pageNum: resp.data.pagingInfo.pageNumber,
                    pageSize: resp.data.pagingInfo.pageSize,
                    totalItems: resp.data.pagingInfo.totalItems
                }
            }
        });
    }

    createLeadAsync(req: LeadCreationRq): Promise<ILead> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as ILead;
        });
    }

    bulkcreateLeadAsync(req: BulkLeadCreationRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);
            return await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    updateLeadStatusAsync(req: LeadStatusUpdationRq): Promise<ILead> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as ILead;
        });
    }

    updateLeadContactAsync(req: LeadContactUpdateRq): Promise<ILead> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as ILead;
        });
    }

    fetchLeadAsync(req: LeadFetchRq): Promise<ILead> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as ILead;
        });
    }

    deleteLeadAsync(req: LeadDeletionRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            await axios.default.delete(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    restoreLeadAsync(req: LeadRestorationRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, null, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    listLeadsAsync(req: LeadListingRq): Promise<PagedList<ILead>> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return {
                items: (resp.data.items ?? []).map((x: any) => Object.assign({}, x) as ILead),
                pagingInfo: {
                    pageNum: resp.data.pagingInfo.pageNumber,
                    pageSize: resp.data.pagingInfo.pageSize,
                    totalItems: resp.data.pagingInfo.totalItems
                }
            }
        });
    }

    saveLeadProductDetailsAsync(req: LeadProductDataUpdateRq): Promise<ILead> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as ILead;
        });
    }

    saveCommentAsync(req: AddCommentRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        })
    }

    saveCallSummaryAsync(req: AddCallSummaryRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    sendMailAsync(req: SendEmailRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    updateAssignedUsersAsync(req: UpdateAssignedUsers): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    getLeadrecentActions(req: LeadRecentActionsReq): Promise<PagedList<ILeadRecentAction>> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return {
                items: (resp.data.items ?? []).map(
                    (x: any) => Object.assign(
                        {}, x
                    ) as ILeadRecentAction),
                pagingInfo: {
                    pageNum: resp.data.pagingInfo.pageNumber,
                    pageSize: resp.data.pagingInfo.pageSize,
                    totalItems: resp.data.pagingInfo.totalItems
                }
            }
        });
    }

    updateNextActionAsync(req: NextActionUpdateRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    updateClientRMAsync(req: ClientRMUpdateRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }


    verifyLeadEmailAsync(req: LeadEmailVerificationRq): Promise<any> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as any;
        });
    }

    createLeadAuditAsync(req: LeadAuditCreationRq): Promise<any> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as any;
        });
    }


    updateLeadSourceRqAsync(req: UpdateLeadSourceRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    fetchAllLeadsAsync(req: LeadListingRq) {
        debugger
        return this.safelyExecuteAsync(async () => {

            req = req.clone();
            req.fetchAll = false;

            req.fetchAll = false;
            req.pageNum = req.pageSize = 1;

            var { url } = req.getApiCallParams(this.baseUrl);

            var statusResp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });




            let totalItems = statusResp.data.pagingInfo.totalItems ?? 0;
            let allItems: any = {};
            let threadLoad = 80, perCall = 20;
            let threads = Math.ceil(totalItems / threadLoad);

            let threadDef = (index: number) => {
                let from = threadLoad * index;
                let to = from + threadLoad - 1;
                if (to > statusResp.data.pagingInfo.totalItems)
                    to = statusResp.data.pagingInfo.totalItems;

                let operationHandle: Promise<void> = Promise.resolve();
                let remaining = threadLoad;
                let currReq = req.clone();
                currReq.pageNum = (from / perCall) + 1;
                currReq.pageSize = perCall;

                while (remaining > 0) {
                    var { url } = currReq.getApiCallParams(this.baseUrl);
                    operationHandle = (async function (_url: string, _headers: any, _operationHandle: Promise<void>) {
                        await _operationHandle;
                        const resp = await axios.default.get(_url, { headers: _headers });
                        for (let _item of (resp.data.items ?? [])) {
                            allItems[_item.id] = _item;
                        }
                    })(url, this.getHeaders(this.token as string, this.tenantId), operationHandle);

                    if ((to - from) < perCall)
                        break;

                    remaining -= perCall;
                    currReq = currReq.clone();
                    currReq.pageNum += 1;
                }

                return operationHandle;
            }

            let allThreads = [];
            for (let i = 0; i < threads; i++)
                allThreads.push(threadDef(i));

            await Promise.all(allThreads);

            let allLeads = Object.values(allItems);

            return {
                items: (allLeads ?? []).map((x: any) => Object.assign({}, x) as ILead),

                pagingInfo: {
                    pageNum: statusResp.data.pagingInfo.pageNumber,
                    pageSize: statusResp.data.pagingInfo.pageSize,
                    totalItems: statusResp.data.pagingInfo.totalItems
                }
            }
        });
    }
}