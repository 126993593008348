import { IAuthProvider } from "../interfaces/IAuthProvider";
import { IUser } from "../models/user";
import * as axios from "axios";
import { ApiRouteKeys } from "../settings";
import { UserType } from "../models/enums";
import { ServiceBase } from "./ServiceBase";
import { IAuthState } from "../interfaces/IAuthState";

export class AuthProvider extends ServiceBase implements IAuthProvider {
    constructor(baseUrl: string, tenantId: string, private appLink: string) {
        super(baseUrl, tenantId, null);

        if ((appLink = (appLink ?? "").trim()).length == 0)
            throw new Error("app link is mandatory");
    }

    public async validateTokenAsync(token: string): Promise<{ user: IUser; isValid: boolean; role: any; }> {
        return this.safelyExecuteAsync(async () => {
            var resp = await axios.default.post(
                `${this.baseUrl}/${ApiRouteKeys.Auth}/${ApiRouteKeys.ValidateToken}`,
                {
                    token: token
                }
            );

            return resp.data as { user: IUser; isValid: boolean; role: any; };
        });
    }

    public async setPasswordAsync(token: string, password: string): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            await axios.default.post(
                `${this.baseUrl}/${ApiRouteKeys.Auth}/${ApiRouteKeys.SetPassword}`,
                {
                    token: token,
                    password: password
                }
            );
        });
    }

    public async extendTokenAsync(token: string): Promise<{ user: IUser, token: string, orgLogoUrl: string, role: any, orgWebsite: string, parent: { website: string, address: string } }> {
        return this.safelyExecuteAsync(async () => {
            var resp = await axios.default.post(
                `${this.baseUrl}/${ApiRouteKeys.Auth}/${ApiRouteKeys.ExtendToken}`,
                {
                    token: token
                }
            );

            return resp.data as { user: IUser, token: string, orgLogoUrl: string, role: any, orgWebsite: string, parent: { website: string, address: string } };
        });
    }

    async activateAccountAsync(token: string, password: string): Promise<IUser> {
        return this.safelyExecuteAsync(async () => {
            var resp = await axios.default.post(
                `${this.baseUrl}/${ApiRouteKeys.Auth}/${ApiRouteKeys.ActivateAccount}`,
                {
                    token: token,
                    password: password
                }
            );

            return resp.data as IUser;
        });
    }

    async sendRecoveryLinkAsync(userEmailId: string): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            await axios.default.post(
                `${this.baseUrl}/${ApiRouteKeys.Auth}/${ApiRouteKeys.RecoverAccount}`,
                {
                    emailId: userEmailId,
                    appLink: this.appLink
                }
            );
        });
    }

    async updatePasswordAsync(userId: string, oldPassword: string, newPassword: string): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            await axios.default.post(
                `${this.baseUrl}/${ApiRouteKeys.Auth}/${ApiRouteKeys.UpdatePassword}`,
                {
                    userId: userId,
                    oldPassword: oldPassword,
                    newPassword: newPassword
                }
            );
        });
    }

    async loginAsync(emailId: string, password: string): Promise<{ orgId: string, user: IUser; token: string; userType: UserType, orgLogoUrl: string, role: any, orgWebsite: string, parent: { website: string, address: string } }> {
        return this.safelyExecuteAsync(async () => {
            var resp = await axios.default.post(
                `${this.baseUrl}/${ApiRouteKeys.Auth}/${ApiRouteKeys.Login}`,
                {
                    emailId: emailId,
                    password: password
                }
            );

            return resp.data as { user: IUser; token: string; userType: UserType; orgId: string, orgLogoUrl: string, role: any, orgWebsite: string, parent: { website: string, address: string } };
        });
    }
}