import VerticalNavMenuLink from './VerticalNavMenuLink';
import VerticalNavMenuGroup from './VerticalNavMenuGroup';
import VerticalNavMenuSectionHeader from './VerticalNavMenuSectionHeader';
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '../../../utils';

export default function VerticalMenuNavItems(props: any) {
  const Components = {
    VerticalNavMenuSectionHeader,
    VerticalNavMenuGroup,
    VerticalNavMenuLink
  }

  // ** Render Nav Menu Items
  const RenderNavItems = props.items.map((item: any, index: number) => {
    const TagName: any = Components[resolveNavItemComponent(item)]

    return <TagName key={item.id || item.header} item={item} {...props} />
  });

  return RenderNavItems;
}