import { IBankProvider } from "../interfaces/IBankProvider";
import { IBank, IBankDepartment } from "../models/bank";
import { BankFetchRq, BankCreationRq, BankUpdationRq, ListBanksRq, BankDeptCreationRq, BankDeletionRq, BankDeptDeletionRq, BankDeptUpdationRq, BankOfferGracePeriodUpdationRq, BankStatusUpdationRq, BankBulkDeletionRq } from "../models/bank-requests";
import { ServiceBase } from "./ServiceBase";
import { PagedList } from "../models/pagedList";
import { IAuthState } from "../interfaces/IAuthState";
import * as axios from "axios";
import { ImageType } from "../models/enums";
import { ApiRouteKeys } from "../settings";

export class BankProvider extends ServiceBase implements IBankProvider {
    constructor(baseUrl: string, tenantId: string, authState: IAuthState) {
        super(baseUrl, tenantId, authState);
    }

    createBankAsync(req: BankCreationRq): Promise<IBank> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IBank;
        });
    }

    listBanksAsync(req: ListBanksRq): Promise<PagedList<IBank>> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return {
                items: (resp.data.items ?? []).map((x: any) => Object.assign({}, x) as IBank),
                pagingInfo: {
                    pageNum: resp.data.pagingInfo.pageNumber,
                    pageSize: resp.data.pagingInfo.pageSize,
                    totalItems: resp.data.pagingInfo.totalItems
                }
            }
        });
    }

    updateBankAsync(req: BankUpdationRq): Promise<IBank> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.put(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IBank;
        });
    }

    updateBankStatusAsync(req: BankStatusUpdationRq): Promise<IBank>{
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IBank;
        });
    }

    fetchBankAsync(req: BankFetchRq): Promise<IBank> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IBank;
        });
    }

    saveLogoAsync(bankId: string, file: File): Promise<void> {
        return this.safelyExecuteAsync(async () => {    
            var formData = new FormData();
            formData.append("logo", file);

            await axios.default.post(
                `${this.baseUrl}/${ApiRouteKeys.Banks}/${bankId}/logo`,
                formData
            );
        });
    }

    removeLogoAsync(bankId: string): Promise<void> {
        return this.safelyExecuteAsync(async () => {    
            await axios.default.post(
                `${this.baseUrl}/${ApiRouteKeys.Banks}/${bankId}/logo`,
                {
                    removeFile: true
                }
            );
        });
    }

    deleteBankAsync(req: BankDeletionRq):Promise<void>{
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            await axios.default.delete(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }


    addBankDepartmentAsync(req: BankDeptCreationRq):Promise<IBankDepartment>{
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IBankDepartment;
        });
    }

    removeBankDepartmentAsync(req: BankDeptDeletionRq): Promise<void>{
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            await axios.default.delete(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    updateDepartmentAsync(req: BankDeptUpdationRq): Promise<IBankDepartment>{
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.put(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IBankDepartment;
        });
    }

    updateOfferGracePeriodAsync(req: BankOfferGracePeriodUpdationRq) :Promise<IBank> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IBank;
        });
    }

}
