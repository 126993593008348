export interface IToast{
    type: string;
    message: string;
}

export class ToastState{
    private listeners: ((toast: IToast) => void)[] = [];

    private constructor(){}

    show(toast: IToast){
        this.listeners.forEach(listener => {
            try{
                listener(toast);
            }catch(err){
                console.error(err);
            }
        });
    }

    addListener(listener: (toast: IToast) => void){
        if(this.listeners.includes(listener))
            return;

        this.listeners.push(listener);
    }

    public static readonly instance: ToastState = new ToastState();
}