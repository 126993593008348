import { NwPartnerIndividualUserSuspensionRQ } from './../models/user-suspension-requests';
import { IEntityProvider } from "../interfaces/IEntityProvider";
import { EntityType, OrgType, UserType } from "../models/enums";
import { INwPartner, NwPartnersListingRQ } from "../models/nw-partners-listing-requests";
import { OrgCreationRQ } from "../models/org-creation-requests";
import { OrgFetchRQ } from "../models/org-fetch-requests";
import { OrgsListingRQ } from "../models/org-listing-requests";
import { OrgUpdationRQ } from "../models/org-updation-requests";
import { IOrganization } from "../models/organization";
import { PagedList } from "../models/pagedList";
import { PagingInfo } from "../models/pagingInfo";
import { IUser } from "../models/user";
import { UserCreationRQ } from "../models/user-creation-requests";
import { UserFetchRQ } from "../models/user-fetch-requests";
import { UsersListingRQ } from "../models/user-listing-requests";
import { UserUpdationRQ } from "../models/user-updation-requests";
import * as axios from "axios";
import {  IUserStats } from "../models/user-stats";
import { ApiRouteKeys } from "../settings";
import { UserNotificationSettingsUpdateRQ } from "../models/user-notification-settings-requests";
import { OrgDeleteRQ } from "../models/org-delete-requests";
import { NwPartnerCompanyDeletionRQ, UserDeletionRQ } from "../models/user-deletion-requests";
import { NwPartnerCompanySuspensionRQ, UserSuspensionRQ } from "../models/user-suspension-requests";
import { NwPartnerCompanyActivationRQ, NwPartnerIndividualActivationRQ, ResendActivationRQ, UserActivationRQ } from "../models/user-activation-requests";
import { ServiceBase } from "./ServiceBase";
import { BulkNwPartnerCompanyActivationRQ, BulkNwPartnerIndividualActivationRQ, BulkUserActivationRQ } from "../models/bulk-user-activation-request";
import { BulkNwPartnerSuspensionRQ, BulkUserSuspensionRQ } from "../models/bulk-user-suspension-request";
import { BulkNwPartnerDeletionRQ, BulkUserDeletionRQ } from "../models/bulk-user-deletion-request";
import { IAuthState } from '../interfaces/IAuthState';

export class EntityProvider extends ServiceBase implements IEntityProvider{
    constructor(baseUrl: string, tenantId: string, private appLink: string, authState: IAuthState) {
        super(baseUrl, tenantId, authState);

        if((appLink = (appLink ?? "").trim()).length == 0)
            throw new Error("app link is mandatory");
    }

    async activateUserAsync(req: UserActivationRQ): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    async resendActivationLinkAsync(req: ResendActivationRQ): Promise<void>{
        return this.safelyExecuteAsync(async () => {
            var {url, content} = req.getApiCallParams(this.baseUrl);

            content = Object.assign({}, content, {appLink: this.appLink});

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    async suspendUserAsync(req: UserSuspensionRQ): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    async deleteUserAsync(req: UserDeletionRQ): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url} = req.getApiCallParams(this.baseUrl);

            await axios.default.delete(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }
    async bulkactivateUserAsync(BulkUserActivationRQ: BulkUserActivationRQ): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = BulkUserActivationRQ.getApiCallParams(this.baseUrl);
            await axios.default.post(url,content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }
    async bulkactivateNetworkCompanyAsync(BulkNwPartnerCompanyActivationRQ: BulkNwPartnerCompanyActivationRQ): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = BulkNwPartnerCompanyActivationRQ.getApiCallParams(this.baseUrl);
            await axios.default.post(url,content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }
    async bulkactivateNetworkIndividualAsync(BulkNwPartnerIndividualActivationRQ: BulkNwPartnerIndividualActivationRQ): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = BulkNwPartnerIndividualActivationRQ.getApiCallParams(this.baseUrl);
            await axios.default.post(url,content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }
    async bulksuspendNetworkAsync(BulkNwPartnerSuspensionRQ: BulkNwPartnerSuspensionRQ): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = BulkNwPartnerSuspensionRQ.getApiCallParams(this.baseUrl);
            await axios.default.post(url,content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }
    async bulkdeleteNetworkAsync(BulkNwPartnerDeletionRQ: BulkNwPartnerDeletionRQ): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = BulkNwPartnerDeletionRQ.getApiCallParams(this.baseUrl);
            await axios.default.post(url,content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }
    async bulksuspendUserAsync(BulkUserSuspensionRQ: BulkUserSuspensionRQ): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = BulkUserSuspensionRQ.getApiCallParams(this.baseUrl);
            await axios.default.post(url,content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }
    async bulkdeleteUserAsync(BulkUserDeletionRQ: BulkUserDeletionRQ): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url,content} = BulkUserDeletionRQ.getApiCallParams(this.baseUrl);
            await axios.default.post(url,content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }
    async updateUserNotificationSettingsAsync(req: UserNotificationSettingsUpdateRQ): Promise<void>{
        return this.safelyExecuteAsync(async () => {
            var {url, content} = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    async createUserAsync(userCreationRQ: UserCreationRQ): Promise<IUser> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = userCreationRQ.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, Object.assign(content, { appLink: this.appLink }), { headers: this.getHeaders(this.token as string, this.tenantId) });

            if(userCreationRQ.userType == UserType.NetworkPartnerIndividualUser)
                return Object.assign({}, resp.data.partnerUser) as IUser;
            else
                return Object.assign({}, resp.data) as IUser;
        });
    }

    async updateUserAsync(userUpdationRQ: UserUpdationRQ): Promise<IUser> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = userUpdationRQ.getApiCallParams(this.baseUrl);

            var resp = await axios.default.put(url, Object.assign(content, { appLink: this.appLink }), { headers: this.getHeaders(this.token as string, this.tenantId) });

            if(userUpdationRQ.userType == UserType.NetworkPartnerIndividualUser)
                return Object.assign({}, resp.data.partnerUser) as IUser;
            else
                return Object.assign({}, resp.data) as IUser;
        });
    }

    async listUsersAsync(usersListingRQ: UsersListingRQ): Promise<PagedList<IUser>> {
        return this.safelyExecuteAsync(async () => {
            var {url} = usersListingRQ.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return {
                items: (resp.data.items ?? []).map((x: any) => Object.assign({}, x) as IUser),
                pagingInfo: this.getPagingInfo(resp.data)
            }
        });
    }

    async fetchUserAsync(userFetchRQ: UserFetchRQ): Promise<IUser> {
        return this.safelyExecuteAsync(async () => {
            var {url} = userFetchRQ.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return Object.assign({}, resp.data) as IUser;
        });
    }

    async createOrgAsync(orgCreationRQ: OrgCreationRQ): Promise<IOrganization> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = orgCreationRQ.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return orgCreationRQ.orgType == OrgType.NetworkPartnerCompany
                ? Object.assign({}, resp.data.partnerOrg) as IOrganization
                : Object.assign({}, resp.data) as IOrganization;
        });
    }

    async updateOrgAsync(orgUpdationRQ: OrgUpdationRQ): Promise<IOrganization> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = orgUpdationRQ.getApiCallParams(this.baseUrl);

            var resp = await axios.default.put(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return orgUpdationRQ.orgType == OrgType.NetworkPartnerCompany
                ? Object.assign({}, resp.data.partnerOrg) as IOrganization
                : Object.assign({}, resp.data) as IOrganization;
        });
    }
    async activateNetworkIndividualAsync(NwPartnerIndividualActivationRQ: NwPartnerIndividualActivationRQ): Promise<void>{
        return this.safelyExecuteAsync(async () => {
            var {url, content} = NwPartnerIndividualActivationRQ.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    async activateNetworkCompanyAsync(req: NwPartnerCompanyActivationRQ): Promise<void>{
        return this.safelyExecuteAsync(async () => {
            var {url, content} = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }
    async suspendNetworkCompanyAsync(req: NwPartnerCompanySuspensionRQ): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }
    async suspendNetworkIndividualAsync(req: NwPartnerIndividualUserSuspensionRQ): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }
    async deleteNetworkCompanyAsync(req: NwPartnerCompanyDeletionRQ): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url} = req.getApiCallParams(this.baseUrl);

            await axios.default.delete(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }
    async deleteNetworkIndividualAsync(req: NwPartnerIndividualActivationRQ): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url} = req.getApiCallParams(this.baseUrl);

            await axios.default.delete(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    async deleteOrgAsync(orgDeleteRQ: OrgDeleteRQ): Promise<void>{
        return this.safelyExecuteAsync(async () => {
            var {url} = orgDeleteRQ.getApiCallParams(this.baseUrl);

            await axios.default.delete(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    async listOrgsAsync(orgsListingRQ: OrgsListingRQ): Promise<PagedList<{org: IOrganization, primaryContact: IUser}>> {
        return this.safelyExecuteAsync(async () => {
            var {url} = orgsListingRQ.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return {
                items: (resp.data.items ?? []).map((x: any) => Object.assign({}, x) as {org: IOrganization, primaryContact: IUser}),
                pagingInfo: this.getPagingInfo(resp.data)
            }
        });
    }

    async fetchOrgAsync(orgFetchRQ: OrgFetchRQ): Promise<{org: IOrganization, primaryContact: IUser}> {
        return this.safelyExecuteAsync(async () => {
            var {url} = orgFetchRQ.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return Object.assign({}, resp.data) as {org: IOrganization, primaryContact: IUser};
        });
    }

    async listNwPartnersAsync(nwPartnersListingRQ: NwPartnersListingRQ): Promise<PagedList<INwPartner>> {
        return this.safelyExecuteAsync(async () => {
            var {url} = nwPartnersListingRQ.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return {
                items: (resp.data.items ?? []).map(
                    (x: any) => x.partnerType == EntityType.User
                    ? {
                        partner: Object.assign({}, x) as IUser,
                        partnerType: EntityType.User
                    }
                    : Object.assign({
                        partner: x.partner as {org: IOrganization, primaryContact: IUser},
                        partnerType: EntityType.Org,
                        usersCount: x.usersCount
                    })
                ),
                pagingInfo: this.getPagingInfo(resp.data)
            };
        });
    }

    async fetchUserStatsAsync(): Promise<IUserStats>{
        return this.safelyExecuteAsync(async () => {
            var url = `${this.baseUrl}/${ApiRouteKeys.Users}/${ApiRouteKeys.Stats}`;

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return Object.assign({}, {counts: resp.data.count}) as IUserStats;
        });
    }

    async fetchSpecialistsAsync(serviceId: string): Promise<IUser[]>{
        return this.safelyExecuteAsync(async () => {
            var url = `${this.baseUrl}/${ApiRouteKeys.Specialists}/${serviceId}`;

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data.specialists as IUser[];
        });
    }

    async fetchUsersInScopeAsync(): Promise<IUser[]>{
        return this.safelyExecuteAsync(async () => {
            var url = `${this.baseUrl}/${ApiRouteKeys.UsersInScope}`;

            var resp = await axios.default.get(url, {headers: this.getHeaders(this.token as string, this.tenantId)});

            return resp.data.users as IUser[];
        });
    }

    private getPagingInfo(respData: any): PagingInfo{
        return {
            pageNum: respData.pagingInfo.pageNumber,
            pageSize: respData.pagingInfo.pageSize,
            totalItems: respData.pagingInfo.totalItems
        }
    }
}