import {
  Circle,
  CreditCard,
  FileText,
  Home,
  Layers,
  Monitor,
  PieChart,
  Settings,
  User,
  Calendar,
} from "react-feather";

import { Features } from "../../settings";

export default [
  {
    id: "home",
    title: "Dashboard",
    icon: <Home size={20} />,
    navLink: "/home",
    feature: Features.Dashboard,
  },
  {
    id: "leads",
    title: "Leads",
    icon: <CreditCard size={20} />,
    children: [
      {
        id: "add/view",
        title: "Add/View",
        icon: <Circle size={12} />,
        navLink: "/leads",
        feature: Features.LeadManagement,
      },
      {
        id: "source",
        title: "Source Details",
        icon: <Circle size={12} />,
        navLink: "/leads/source",
        feature: Features.LeadSource,
      },
      // {
      //   id: "source",
      //   title: "Add Leads ",
      //   icon: <Circle size={12} />,
      //   navLink: "/leads/addLead",
      // },
    ],
  },
  {
    id: "quote",
    title: "Generate Quote",
    icon: <FileText size={20} />,
    children: [
      {
        id: "view",
        title: "View",
        icon: <Circle size={12} />,
        navLink: "/quotes",
        feature: Features.QuoteGeneration,
      },
      {
        id: "new",
        title: "New Quote",
        icon: <Circle size={12} />,
        navLink: "/quotes/add",
        feature: Features.NewQuoteGeneration,
      },
    ],
  },
  {
    id: "bank",
    title: "Bank",
    icon: <Layers size={20} />,
    children: [
      {
        id: "banks",
        title: "Banks",
        icon: <Circle size={12} />,
        navLink: "/banks",
        feature: Features.Banks,
      },
      {
        id: "rm",
        title: "Bank RM",
        icon: <Circle size={12} />,
        navLink: "/banks/rm",
        feature: Features.BankRM,
      },
      {
        id: "offers",
        title: "Bank Offers",
        icon: <Circle size={12} />,
        navLink: "/banks/offers",
        feature: Features.BankOffer,
      },
      {
        id: "rules",
        title: "Bank Rules",
        icon: <Circle size={12} />,
        navLink: "/banks/rules",
        feature: Features.BankRules,
      },
      {
        id: "developer",
        title: "Approved Developer",
        icon: <Circle size={12} />,
        navLink: "/banks/developer",
        feature: Features.BankApprovedDeveloper,
      },
      {
        id: "rates",
        title: "EIBOR Rates",
        icon: <Circle size={12} />,
        navLink: "/banks/rates",
        feature: Features.BankEiboreRate,
      },
    ],
  },
  {
    id: "calculator",
    title: "Calculator",
    icon: <Monitor size={20} />,
    children: [
      {
        id: "bt",
        title: "BT Calculator",
        icon: <Circle size={12} />,
        navLink: "/calculator-bt",
        feature: Features.BTCalculator,
      },

      {
        id: "emi",
        title: "EMI Calculator",
        icon: <Circle size={12} />,
        navLink: "/calculator-emi",
        feature: Features.EMICalculator,
      },
    ],
  },
  {
    id: "user",
    title: "Users",
    icon: <User size={20} />,
    navLink: "/users",
    feature: Features.UsersTab,
  },
  {
    id: "calendar",
    title: "Calendar",
    icon: <Calendar size={20} />,
    navLink: "/leads/calendar",
    feature: Features.Calendar,
  },
  {
    id: "settings",
    title: "Settings",
    icon: <Settings size={20} />,
    children: [
      {
        id: "gen",
        title: "General",
        icon: <Circle size={12} />,
        navLink: "/settings/general",
        feature: Features.Settings,
      },

      {
        id: "dash",
        title: "Dashboard",
        icon: <Circle size={12} />,
        navLink: "/settings/dashboard",
        feature: Features.Settings,
      },
      {
        id: "role",
        title: "Role Management",
        icon: <Circle size={12} />,
        navLink: "/settings/role",
        feature: Features.Settings,
      },
      {
        id: "smtp",
        title: "SMTP Settings",
        icon: <Circle size={12} />,
        navLink: "/settings/smtp",
        feature: Features.Settings,
      },
      {
        id: "status",
        title: "Status List",
        icon: <Circle size={12} />,
        navLink: "/settings/status",
        feature: Features.Settings,
      },
      {
        id: "leads",
        title: "Lead Settings",
        icon: <Circle size={12} />,
        navLink: "/settings/leads",
        feature: Features.Settings,
      },
      {
        id: "offers",
        title: "Bank Offer Settings",
        icon: <Circle size={12} />,
        navLink: "/settings/offers",
        feature: Features.Settings,
      },
      {
        id: "eibor",
        title: "EIBOR Settings",
        icon: <Circle size={12} />,
        navLink: "/settings/eibor",
        feature: Features.Settings,
      },
      {
        id: "developer",
        title: "Developer List",
        icon: <Circle size={12} />,
        navLink: "/settings/developer",
        feature: Features.Settings,
      },
      {
        id: "products",
        title: "Services",
        icon: <Circle size={12} />,
        navLink: "/settings/products",
        feature: Features.Settings,
      },
      {
        id: "notification",
        title: "Notification",
        icon: <Circle size={12} />,
        navLink: "/settings/notification",
        feature: Features.Settings,
      },
      {
        id: "systemInfo",
        title: "System Info",
        icon: <Circle size={12} />,
        navLink: "/settings/systemInfo",
        feature: Features.Settings,
      },
    ],
  },
];
