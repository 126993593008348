import { IAuthProvider } from "../interfaces/IAuthProvider";
import { IAuthInfo, IAuthState } from "../interfaces/IAuthState";
import { UserType } from "../models/enums";
import { IUser } from "../models/user";
import { Features } from "../settings";

export class AuthState implements IAuthState {

    private token: string | null = null;
    private user: IUser | null = null;
    private userType: UserType | null = null;
    private orgId: string | null = null;
    private orgLogoUrl: string | null = null;
    private orgWebsite?: string | null;
    private isLoading: boolean = true;
    private parent: { website: string, address: string } | null = null;
    private role: any;
    private readonly authKey: string = "lmc-auth";
    private listeners: ((authInfo: IAuthInfo) => void)[] = [];

    constructor(private authProvider: IAuthProvider) {
        var authData = null;
        try { authData = localStorage.getItem(this.authKey); }
        catch (error) { }
        if (!!authData) {
            var parsed = JSON.parse(authData);
            this.authProvider.validateTokenAsync(parsed.token)
                .then(resp => {
                    if (!resp.isValid)
                        return;
                    this.update(parsed.token, parsed.user, parsed.userType, parsed.orgId, parsed.orgLogoUrl, parsed.role, parsed.orgWebsite, parsed.parent);
                }, (err) => {
                    console.log(err);
                    this.clear();
                    window.location.hash = "login";
                }).then(() => {
                    this.isLoading = false;
                    this.notifyAll();
                });
        } else {
            this.isLoading = false;
            this.notifyAll();
        }
    }

    public isSystemAdmin(): boolean {
        return !!this?.user?.attributes?.identity?.isAdmin && this.userType === UserType.Internal;
    }

    public isAuthenticated(): boolean {
        return !!this?.token;
    }

    public isAccessible(feature: string, capability: string | null): boolean {
        if (this.userType == UserType.Internal && this.user?.attributes.identity.isAdmin == true)
            return true;

        var featureMatch = (this.role?.features ?? []).find((x: any) => x.key == feature);

        if (!(!!featureMatch))
            return false;

        if (!(!!capability))
            return !!(featureMatch.capabilities ?? []).find((x: any) => x.value == true);

        return !!(featureMatch.capabilities ?? []).find((x: any) => x.key == capability && x.value == true);
    }

    public getUserDashboard(): any {
        if (this.userType == UserType.Internal && this.user?.attributes.identity.isAdmin == true)
            return { isAdmin: true };
        if (!this.role || !this.role.features || !Array.isArray(this.role.features)) {
            return null;
        }
        return this.role.features.find((feature: any) =>
            !!feature && !!feature.key && feature.key === Features.Dashboard);
    }

    public triggerRefresh() {
        this.authProvider.extendTokenAsync(this.token as string)
            .then((resp) => {
                this.update(resp.token, resp.user, this.userType, this.orgId, resp.orgLogoUrl, resp.role, resp.orgWebsite, resp.parent);
            })
    }

    public onAuthChanged(callback: (authInfo: IAuthInfo) => void): () => void {
        let unubscribe = () => {
            this.listeners = this.listeners.filter(x => x != callback);
        }

        if (this.listeners.includes(callback))
            return unubscribe;

        this.listeners.push(callback);

        this.notifyAll();

        return unubscribe;
    }

    public clear() {
        this.token = null;
        this.user = null;
        try { localStorage.clear(); } catch (error) { }
        this.notifyAll();
    }

    public update(token: string, user: IUser | null = null, userType: UserType | null = null, orgId: string | null = null, orgLogoUrl: string | null = null, role: any | null = null, orgWebsite: string | null, parent: { website: string, address: string }) {
        this.token = token;
        this.user = user;
        this.userType = userType;
        this.orgId = orgId;
        this.orgLogoUrl = orgLogoUrl;
        this.role = role;
        this.orgWebsite = orgWebsite;
        this.parent = parent;
        try { localStorage.setItem(this.authKey, JSON.stringify({ token: this.token, user: this.user, userType: this.userType, orgId: this.orgId, orgLogoUrl: this.orgLogoUrl, role: this.role, orgWebsite: this.orgWebsite })); } catch (error) { }

        this.notifyAll();

        if (!!this.token)
            this.performTokenRefresh();
    }

    private notifyAll() {
        this.listeners.forEach(listener => {
            try {
                listener({
                    token: this.token,
                    user: this.user,
                    userType: this.userType,
                    orgId: this.orgId,
                    isLoading: this.isLoading,
                    orgLogoUrl: this.orgLogoUrl,
                    orgWebsite: this.orgWebsite,
                    parent: this.parent
                });
            } catch (err) { console.log(err); }
        });
    }

    private async performTokenRefresh() {
        if (!(!!this.token))
            return;

        try {
            var resp = await this.authProvider.extendTokenAsync(this.token);
            if (!(!!this.token))
                return;

            this.token = resp.token;
            this.user = resp.user;
            this.role = resp.role;
            this.orgWebsite = resp.orgWebsite;
            this.parent = resp.parent;

            var authData = null;
            try { authData = localStorage.getItem(this.authKey); }
            catch (error) { }

            if (!!authData) {
                var parsed = JSON.parse(authData);
                parsed.token = resp.token;
                try { localStorage.setItem(this.authKey, JSON.stringify(parsed)); } catch (error) { }
            }

            this.notifyAll();
        } finally {
            if (!!this.token)
                setTimeout(() => this.performTokenRefresh(), 20 * 60 * 1000);
        }
    }
}
