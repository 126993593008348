import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import { ArrowUp } from "react-feather";
import ScrollToTop from "react-scroll-up";
import { Navbar, Button } from "reactstrap";

import themeConfig from "../configs/themeConfig";
import Customizer from "../components/customizer";
import FooterComponent from "./components/footer";
import NavbarComponent from "./components/navbar";
import SidebarComponent from "./components/menu/vertical-menu";

import "../styles/base/core/menu/menu-types/vertical-menu.scss"
import "../styles/base/core/menu/menu-types/vertical-overlay-menu.scss"

export default function Layout(props: any) {
  const { children, navbar, footer, menu, routerProps, currentActiveItem } = props;
  const location = useLocation();
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [menuCollapsed, setMenuCollapsed] = useState<boolean>(false);
  const [menuVisibility, setMenuVisibility] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<any>(window.innerWidth);
  const footerType: any = "static";
  const navbarType: any = "floating";
  const isHidden: boolean = false;

  // ** Update Window Width
  const handleWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  }

  //** This function will detect the Route Change and will hide the menu on menu item click
  useEffect(() => {
    if (menuVisibility && windowWidth < 1200) {
      setMenuVisibility(false);
    }
  }, [location]);

  //** Sets Window Size & Layout Props
  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener("resize", handleWindowWidth);
    }
  }, [windowWidth]);

  //** ComponentDidMount
  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  // ** Vars
  const footerClasses = {
    static: "footer-static",
    sticky: "footer-fixed",
    hidden: "footer-hidden"
  }

  const navbarWrapperClasses = {
    floating: "navbar-floating",
    sticky: "navbar-sticky",
    static: "navbar-static",
    hidden: "navbar-hidden"
  }

  const navbarClasses = {
    floating: "floating-nav",
    sticky: "fixed-top",
    static: "navbar-static-top",
    hidden: "d-none"
  }

  // const bgColorCondition = navbarColor !== "" && navbarColor !== "light" && navbarColor !== "white"

  if (!isMounted) {
    return null
  }
  return (
    <div
      className={classnames(
        `wrapper vertical-layout ${navbarWrapperClasses["floating"] || "navbar-floating"} ${footerClasses["static"] || "footer-static"
        }`,
        {
          // Modern Menu
          "vertical-menu-modern": windowWidth >= 1200,
          "menu-collapsed": menuCollapsed && windowWidth >= 1200,
          "menu-expanded": !menuCollapsed && windowWidth > 1200,

          // Overlay Menu
          "vertical-overlay-menu": windowWidth < 1200,
          "menu-hide": !menuVisibility && windowWidth < 1200,
          "menu-open": menuVisibility && windowWidth < 1200
        }
      )}
      {...(isHidden ? { "data-col": "1-column" } : {})}
    >
      {!isHidden ? (
        <SidebarComponent
          // skin={skin}
          menu={menu}
          menuCollapsed={menuCollapsed}
          menuVisibility={menuVisibility}
          setMenuCollapsed={setMenuCollapsed}
          setMenuVisibility={setMenuVisibility}
          routerProps={routerProps}
          currentActiveItem={currentActiveItem}
        />
      ) : null}

      <Navbar
        expand="lg"
        light={true}
        dark={false}
        // color={bgColorCondition ? navbarColor : undefined}
        className={classnames(
          `header-navbar navbar align-items-center ${navbarClasses["floating"] || "floating-nav"} navbar-shadow`
        )}
      >
        <div className="navbar-container d-flex content">
          {/* {navbar ? (
            navbar({ setMenuVisibility, skin, setSkin })
          ) : ( */}
          <NavbarComponent setMenuVisibility={setMenuVisibility} skin={null} setSkin={null} />
          {/* )} */}
        </div>
      </Navbar>
      {children}

      {/* Vertical Nav Menu Overlay */}
      <div
        className={classnames("sidenav-overlay", {
          show: menuVisibility
        })}
        onClick={() => setMenuVisibility(false)}
      ></div>
      {/* Vertical Nav Menu Overlay */}

      {themeConfig.layout.customizer === true ? (
        <Customizer
          // skin={skin}
          // setSkin={setSkin}
          footerType={footerType}
          // setFooterType={setFooterType}
          navbarType={navbarType}
          // setNavbarType={setNavbarType}
          navbarColor={"light"}
          // setNavbarColor={setNavbarColor}
          isRtl={false}
          // setIsRtl={setIsRtl}
          layout={props.layout}
          setLayout={props.setLayout}
          isHidden={isHidden}
          // setIsHidden={setIsHidden}
          // contentWidth={contentWidth}
          // setContentWidth={setContentWidth}
          menuCollapsed={menuCollapsed}
          setMenuCollapsed={setMenuCollapsed}
          transition={props.transition}
          setTransition={props.setTransition}
          themeConfig={themeConfig}
        />
      ) : null}
      <footer
        className={classnames(`footer footer-light ${footerClasses["static"] || "footer-static"}`, {
          "d-none": footerType === "hidden"
        })}
      >
        {footer ? (
          footer({ footerType, footerClasses })
        ) : (
          <FooterComponent footerType={footerType} footerClasses={footerClasses} />
        )}
      </footer>

      {themeConfig.layout.scrollTop === true ? (
        <div className="scroll-to-top">
          <ScrollToTop showUnder={300} style={{ bottom: "5%" }}>
            <Button className="btn-icon" color="primary">
              <ArrowUp size={14} />
            </Button>
          </ScrollToTop>
        </div>
      ) : null}
    </div>
  );
}