import { IAuthState } from "../interfaces/IAuthState";
import { IQuotationProvider } from "../interfaces/IQuotationProvider";
import { ApplicableOffersListingRq, FindCustomerQuotationsRq, FindQuotationCustomersRq, QuotationCreationRq, QuotationFetchRq, QuotationPurgeRq, QuotationsListingRq, QuotationStatsFetchRq, UpdateQuotationAssignUsers, UpdateQuoteAssignedUsers } from "../models/quotation-requests";
import { IApplicableOffer } from "../models/quotation-service-models";
import { ServiceBase } from "./ServiceBase";
import * as axios from "axios";
import { IAbout, IQuotationSnapshot } from "../pages/quote/quotation-models";
import { PagedList } from "../models/pagedList";
import { SettingsTransform } from "../models/ISettings";

export class QuotationProvider extends ServiceBase implements IQuotationProvider {
    constructor(baseUrl: string, tenantId: string, authState: IAuthState) {
        super(baseUrl, tenantId, authState);
    }

    findApplicableOffersAsync(req: ApplicableOffersListingRq): Promise<IApplicableOffer[]> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data.applicableOffers as IApplicableOffer[];
        });
    }

    async createQuotationAsync(req: QuotationCreationRq): Promise<{ quoteId: string }> {

        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            const response = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
            return response?.data;
        });

    }

    async purgeQuotationAsync(req: QuotationPurgeRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            await axios.default.delete(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    async fetchQuotationAsync(req: QuotationFetchRq): Promise<IQuotationSnapshot> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);
            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return this.toSnapshotModel(resp.data)
        });
    }

    async fetchQuotationStatsAsync(req: QuotationStatsFetchRq): Promise<{ count: number }> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);
            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
            return resp.data;
        });
    }

    async listQuotationsAsync(req: QuotationsListingRq): Promise<PagedList<IQuotationSnapshot>> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);
            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
            return {
                items: (resp.data.items ?? []).map((x: any) => this.toSnapshotModel(x)),
                pagingInfo: {
                    pageNum: resp.data.pagingInfo.pageNumber,
                    pageSize: resp.data.pagingInfo.pageSize,
                    totalItems: resp.data.pagingInfo.totalItems
                }
            }
        });
    }

    async findCustomersAsync(req: FindQuotationCustomersRq): Promise<IAbout[]> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);
            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IAbout[];
        });
    }

    async findCustomerQuotationsAsync(req: FindCustomerQuotationsRq): Promise<IQuotationSnapshot[]> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);
            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IQuotationSnapshot[];
        });
    }

    private toSnapshotModel(data: any) {
        let parsed: any = Object.assign({}, data);

        ["loanTypes", "productTypes", "clientProfiles", "documentTypes", "propertyTypes", "propertyLocations"].forEach(key => {
            if (!(!!parsed[key]))
                return;

            parsed[key] = (parsed[key] ?? []).map(
                (toTransform: any) => SettingsTransform.toBankOfferSettings(toTransform)
            );
        })

        parsed.eiborMonths = (parsed.eiborMonths ?? []).map((x: any) => SettingsTransform.toEiBoreMonthsSettings(x));
        parsed.eiborSettings = (parsed.eiborSettings ?? []).map((x: any) => SettingsTransform.toEiBoreSettings(x));

        return parsed as IQuotationSnapshot;
    }

    updateQuoteAssignedUsersAsync(req: UpdateQuoteAssignedUsers): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    updateQuoteAssignedUsersFromLeadAsync(req: UpdateQuotationAssignUsers): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }
}