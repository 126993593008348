import { Suspense, useContext, lazy, useState } from "react";
import { HashRouter  as AppRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";
import { AbilityContext } from "../contexts/canContext";
import LayoutWrapper from "../layouts/components/wrapper";
import BlankLayout from "../layouts/BlankLayout";
import Layout from "../layouts";
import { DefaultRoute, Routes } from "./routes";
import { useRef } from "react";
import { useEffect } from "react";
import { ContainerContext } from "../contexts/containerContext";
import { DependencyContainer } from "tsyringe";
import { DependencyTokens } from "../settings";
import { IAuthInfo, IAuthState } from "../interfaces/IAuthState";
import { Spinner } from "reactstrap";

export default function Router() {
    const container = useContext(ContainerContext) as DependencyContainer;
    const authState = container.resolve<IAuthState>(DependencyTokens.defaultAuthState);
    const ability: any = useContext(AbilityContext);
    const DefaultLayout: string = "VerticalLayout";
    const Layouts: any = { BlankLayout, Layout }
    const [authInfo, setAuthInfo] = useState<IAuthInfo>();
    var history = useHistory();
    var authHandler = useRef<any>((authInfo: IAuthInfo) => {
        setAuthInfo(authInfo);
        if(!!authInfo && !authInfo.isLoading && !!authInfo.token)
            history?.push('/login');
    });

    useEffect(() => {
        let unsubscribe = authState.onAuthChanged(authHandler.current);

        return () => unsubscribe();
    }, []);

    // ** Current Active Item
    const currentActiveItem: any = null

    const NotAuthorized = lazy(() => import("../pages/unauthorize"));
    const Error = lazy(() => import("../pages/error"));

    // ** Return Filtered Array of Routes & Paths
    const LayoutRoutesAndPaths = (layout: any) => {
        const LayoutRoutes: any[] = [];
        const LayoutPaths: any[] = [];

        if (Routes) {
            Routes.forEach((route: any) => {
                // ** Checks if Route layout or Default layout matches current layout
                if(!!route.meta.authRoute && !!route.meta.feature && authState.isAccessible(route.meta.feature, null) == false)
                    return;
                if (route.layout === layout || (route.layout === undefined && DefaultLayout === layout)) {
                    LayoutRoutes.push(route)
                    LayoutPaths.push(route.path)
                }
            });
        }

        return { LayoutRoutes, LayoutPaths }
    }

    const FinalRoute = (props: any) => {
        const route = props.route;
        let action, resource;

        // ** Assign vars based on route meta
        if (route.meta) {
            action = route.meta.action ? route.meta.action : null
            resource = route.meta.resource ? route.meta.resource : null
        }

        if (!(!!authInfo?.token)) {
            /**
             ** If user is not Logged in & route meta is undefined
             ** OR
             ** If user is not Logged in & route.meta.authRoute, !route.meta.publicRoute are undefined
             ** Then redirect user to login
             */

            return <Redirect to="/login" />
        } else if (route.meta && route.meta.authRoute && !!authInfo.token) {
            // ** If route has meta and authRole and user is Logged in then redirect user to home page (DefaultRoute)
            return <Redirect to="/" />
        } else if (!!authInfo.token && !ability.can(action || "read", resource)) {
            // ** If user is Logged in and doesn"t have ability to visit the page redirect the user to Not Authorized
            return <Redirect to="/not-authorized" />
        } else {
            // ** If none of the above render component
            return <route.component {...props} />
        }
    }

    // ** Return Route to Render
    const ResolveRoutes = () => {
        return Object.keys(Layouts).map((layout, index) => {
            // ** Convert Layout parameter to Layout Component
            // ? Note: make sure to keep layout and component name equal

            const LayoutTag: any = Layouts[layout];

            // ** Get Routes and Paths of the Layout
            const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout);

            // ** We have freedom to display different layout for different route
            // ** We have made LayoutTag dynamic based on layout, we can also replace it with the only layout component,
            // ** that we want to implement like VerticalLayout or HorizontalLayout
            // ** We segregated all the routes based on the layouts and Resolved all those routes inside layouts

            // ** RouterProps to pass them to Layouts
            const routerProps: any = {}

            return (
                <Route path={LayoutPaths} key={index}>
                    <LayoutTag
                        routerProps={routerProps}
                        layout={layout}
                        // setLayout={setLayout}
                        // transition={transition}
                        // setTransition={setTransition}
                        currentActiveItem={currentActiveItem}
                    >
                        <Switch>
                            {LayoutRoutes.map(route => {
                                return (
                                    <Route
                                        exact
                                        key={route.path}
                                        path={route.path}
                                        render={props => {
                                            // ** Assign props to routerProps
                                            Object.assign(routerProps, {
                                                ...props,
                                                meta: route.meta
                                            })

                                            return (
                                                <Suspense fallback={<code>Loading...</code>}>
                                                    <LayoutWrapper
                                                        layout={DefaultLayout}
                                                        // transition={transition}
                                                        // setTransition={setTransition}
                                                        {...(route.appLayout
                                                            ? {
                                                                appLayout: route.appLayout
                                                            }
                                                            : {})
                                                        }
                                                        {...(route.meta
                                                            ? {
                                                                routeMeta: route.meta
                                                            }
                                                            : {})
                                                        }
                                                        {...(route.className
                                                            ? {
                                                                wrapperClass: route.className
                                                            }
                                                            : {})
                                                        }
                                                    >
                                                        <route.component {...props} />
                                                        {/* <FinalRoute route={route} {...props} /> */}
                                                    </LayoutWrapper>
                                                </Suspense>
                                            )
                                        }}
                                    />
                                )
                            })}
                        </Switch>
                    </LayoutTag>
                </Route>
            )
        })
    }

    return (!(!!authInfo) || authInfo.isLoading)
    ? (
        <div style={{
            display: "flex",
            minHeight: "100vh",
            width: "100%",
            flexBasis: "100%",
            alignItems: "center",
            justifyItems: "center",
            justifyContent: "space-around"
        }}>
            <Spinner color='primary' size='lg'></Spinner>
        </div>
    )
    : (
        <AppRouter>
            <Suspense fallback={<code>Loading...</code>}>
                <Switch>
                    {/* If user is logged in Redirect user to DefaultRoute else to login */}
                    
                    { <Route
                        exact
                        path="/"
                        render={() => {
                            return !!authInfo?.token ? <Redirect to={DefaultRoute} /> : <Redirect to="/login" />
                        }}
                    /> }
                    {/* Not Auth Route */}
                    <Route
                        exact
                        path="/not-authorized"
                        render={props => (
                            <Layouts.BlankLayout>
                                <NotAuthorized />
                            </Layouts.BlankLayout>
                        )}
                    />
                    
                    {ResolveRoutes()}
                    {/* NotFound Error page */}
                    <Route path="*" component={Error} />/
                </Switch>
            </Suspense>
        </AppRouter>
    );
}