import { useEffect } from "react"
import { Badge } from "reactstrap";
import classnames from "classnames";
import { NavLink, useLocation, matchPath } from "react-router-dom";
import navigation from "../../../../navigation/vertical";
import { search, getAllParents } from "../../../utils";

export default function VerticalNavMenuLink({
  item,
  groupActive,
  setGroupActive,
  activeItem,
  setActiveItem,
  groupOpen,
  setGroupOpen,
  toggleActiveGroup,
  parentItem,
  routerProps,
  currentActiveItem
}: any) {
  // ** Conditional Link Tag, if item has newTab or externalLink props use <a> tag else use NavLink
  const LinkTag: any = item.externalLink ? "a" : NavLink;

  // ** URL Vars
  const location: any = useLocation();
  const currentURL: any = location.pathname;

  // ** To match path
  const match: any = matchPath(currentURL, {
    path: `${item.navLink}/:param`,
    exact: true,
    strict: false
  });

  // ** Search for current item parents
  const searchParents = (navigation: any, currentURL: any) => {
    const parents = search(navigation, currentURL, routerProps); // Search for parent object
    const allParents = getAllParents(parents, "id"); // Parents Object to Parents Array
    return allParents;
  }

  // ** URL Vars
  const resetActiveGroup = (navLink: any) => {
    const parents = search(navigation, navLink, match)
    toggleActiveGroup(item.id, parents)
  }

  // ** Reset Active & Open Group Arrays
  const resetActiveAndOpenGroups = () => {
    setGroupActive([]);
    setGroupOpen([]);
  }

  // ** Checks url & updates active item
  useEffect(() => {
    if (currentActiveItem !== null) {
      setActiveItem(currentActiveItem);
      const arr = searchParents(navigation, currentURL);
      setGroupActive([...arr]);
    }
  }, [location]);

  return (
    <li
      className={classnames({
        "nav-item": !item.children,
        disabled: item.disabled,
        active: item.navLink === activeItem
      })}
    >
      <LinkTag
        className="d-flex align-items-center"
        target={item.newTab ? "_blank" : undefined}
        {...(item.externalLink === true
          ? {
            href: item.navLink || "/"
          }
          : {
            to: item.navLink || "/",
            isActive: (match: any, location: any) => {
              if (!match) {
                return false
              }

              if (match.url && match.url !== "" && match.url === item.navLink) {
                currentActiveItem = item.navLink
              }
            }
          })}
        onClick={(e: any) => {
          if (!item.navLink.length) {
            e.preventDefault()
          }
          parentItem ? resetActiveGroup(item.navLink) : resetActiveAndOpenGroups()
        }}
      >
        {item.icon}
        <span className="menu-item text-truncate">{item.title}</span>

        {item.badge && item.badgeText ? (
          <Badge className="ml-auto mr-1" color={item.badge} pill>
            {item.badgeText}
          </Badge>
        ) : null}
      </LinkTag>
    </li>
  );
}