
import { IBankOfferProvider } from './interfaces/IBankOfferProvider';
import { IApprovedDevelopersProvider } from './interfaces/IApprovedDevelopersProvider';
import { BankRulesProvider } from './impl/BankRulesProvider';
import { IBankRulesProvider } from './interfaces/IBankRulesProvider';
import { IApprovedDeveloper } from './models/approvedDevelopers';
import { ICalendarProvider } from './interfaces/ICalendarProvider';
import { NotificationProvider } from './impl/NotificationProvider';
import { INotificationProvider } from './interfaces/INotificationProvider';
import { container, DependencyContainer } from "tsyringe";
import { AuthProvider } from "./impl/AuthProvider";
import { AuthState } from "./impl/AuthState";
import { ContentService } from "./impl/ContentService";
import { DataSourceAdapter } from "./impl/DataSourceAdapter";
import { EntityProvider } from "./impl/EntityProvider";
import { LeadProvider } from "./impl/LeadProvider";
import { SettingsProvider } from "./impl/SettingsProvider";
import { IAuthProvider } from "./interfaces/IAuthProvider";
import { IAuthState } from "./interfaces/IAuthState";
import { IContentService } from "./interfaces/IContentService";
import { IDatasourceAdapter } from "./interfaces/IDataSourceAdapter";
import { IEntityProvider } from "./interfaces/IEntityProvider";
import { ILeadProvider } from "./interfaces/ILeadProvider";
import { ISettingsProvider } from "./interfaces/ISettingsProvider";
import { DependencyTokens } from "./settings";
import { CalendarProvider } from './impl/CalendarProvider';
import { INotificationState } from './interfaces/INotificationState';
import { NotificationState } from './impl/NotificationState';
import { IEiborRatesProvider } from './interfaces/IEiborRatesProvider';
import { EiborRatesProvider } from './impl/EiborRatesProvider';
import { IBankProvider } from './interfaces/IBankProvider';
import { BankProvider } from './impl/BankProvider';
import { IBankRMProvider } from './interfaces/IBankRmProvider';
import { BankRmProvider } from './impl/BankRmProvider';
import { ApprovedDeveloperProvider } from './impl/ApprovedDevelopersProvider';
import { BankOfferProvider } from './impl/BankOfferProvider';
import { BankOfferContextValue } from './pages/bank/bank-offer/bankOfferContext';
import { IDashboardProvider } from './interfaces/IDashboardProvider';
import { DashboardProvider } from './impl/DashboardProvider';
import { IQuotationProvider } from './interfaces/IQuotationProvider';
import { QuotationProvider } from './impl/QuotationProvider';
import { IQuoteProvider } from './interfaces/IQuoteProvider';
import { QuoteProvider } from './impl/QuoteProvider';
import { ICodeGenerateProvider } from './interfaces/ICodeGenerator';
import { CodeGenerateProvider } from './impl/CodeGenerator';
import { IGeneralSettingsState } from './interfaces/IGeneralSettingsState';
import { GeneralSettingsState } from './impl/GeneralSettingsState';

export class ContainerFactory {
    private static authState: IAuthState;
    private static generalSettingsState: IGeneralSettingsState;
    private static entityProvider: IEntityProvider;
    private static leadsProvider: ILeadProvider;
    private static banksProvider: IBankProvider;
    private static banksRMProvider: IBankRMProvider;
    private static notificationState: INotificationState;
    private static eiborRatesProvider: IEiborRatesProvider;
    private static approvedDevelopersProvider: IApprovedDevelopersProvider;
    private static bankRulesProvider: IBankRulesProvider;
    private static notificationsProvider: INotificationProvider;
    private static calendarProvider: ICalendarProvider;
    private static settingsProvider: ISettingsProvider;
    private static authProvider: IAuthProvider;
    private static bankOfferProvider: IBankOfferProvider;
    private static dashboardProvider: IDashboardProvider;
    private static quotationProvider: IQuotationProvider;
    private static quoteProvider: IQuoteProvider;
    private static codeGeneratorProvider: ICodeGenerateProvider;

    public static getContainer(): DependencyContainer {
        ContainerFactory.configureServices(container);

        return container;
    }

    private static configureServices(container: DependencyContainer) {
        const tenantId = process.env.REACT_APP_TENANTID as string;
        const baseUrl = process.env.REACT_APP_BASE_URL as string;
        const appLink = process.env.REACT_APP_APP_LINK as string;
        const leadsBaseUrl = process.env.REACT_APP_LEADS_BASE_URL as string;
        const notificationBaseUrl = process.env.REACT_APP_NOTIFICATIONS_BASE_URL as string;
        const banksBaseUrl = process.env.REACT_APP_BANKS_BASE_URL as string;

        container.registerType<IDatasourceAdapter>(DependencyTokens.defaultDSAdapter, DataSourceAdapter);

        container.register<IEntityProvider>(
            DependencyTokens.defaultEntityProvider,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.entityProvider)
                        return ContainerFactory.entityProvider;

                    var authState = dependencyContainer.resolve<IAuthState>(DependencyTokens.defaultAuthState);
                    return (ContainerFactory.entityProvider = new EntityProvider(baseUrl, tenantId, appLink, authState));
                }
            }
        );

        container.register<ISettingsProvider>(
            DependencyTokens.defaultSettingsProvider,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.settingsProvider)
                        return ContainerFactory.settingsProvider;

                    var authState = dependencyContainer.resolve<IAuthState>(DependencyTokens.defaultAuthState);
                    return (ContainerFactory.settingsProvider = new SettingsProvider(baseUrl, tenantId, authState));
                }
            }
        );

        container.register<IContentService>(
            DependencyTokens.defaultContentService,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    return new ContentService(baseUrl, tenantId);
                }
            }
        );

        container.register<IAuthProvider>(
            DependencyTokens.defaultAuthProvider,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.authProvider)
                        return ContainerFactory.authProvider;

                    return (ContainerFactory.authProvider = new AuthProvider(baseUrl, tenantId, appLink));
                }
            }
        );

        container.register<IGeneralSettingsState>(
            DependencyTokens.defaultGeneralSettingsState,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.generalSettingsState)
                        return ContainerFactory.generalSettingsState;
                    var settingsProvider = dependencyContainer.resolve<ISettingsProvider>(DependencyTokens.defaultSettingsProvider);
                    var contentService = dependencyContainer.resolve<IContentService>(DependencyTokens.defaultContentService);
                    return (ContainerFactory.generalSettingsState = new GeneralSettingsState(settingsProvider, contentService));
                }
            }
        );

        container.register<IAuthState>(
            DependencyTokens.defaultAuthState,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.authState)
                        return ContainerFactory.authState;

                    var authProvider = dependencyContainer.resolve<IAuthProvider>(DependencyTokens.defaultAuthProvider);
                    return (ContainerFactory.authState = new AuthState(authProvider));
                }
            }
        );

        container.register<INotificationState>(
            DependencyTokens.defaultNotificationState,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.notificationState)
                        return ContainerFactory.notificationState;

                    return (ContainerFactory.notificationState = new NotificationState());
                }
            }
        );

        container.register<ILeadProvider>(
            DependencyTokens.defaultLeadsProvider,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.leadsProvider)
                        return ContainerFactory.leadsProvider;

                    var authState = dependencyContainer.resolve<IAuthState>(DependencyTokens.defaultAuthState);
                    return (ContainerFactory.leadsProvider = new LeadProvider(leadsBaseUrl, tenantId, authState));
                }
            }
        );

        container.register<IBankProvider>(
            DependencyTokens.defaultBanksProvider,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.banksProvider)
                        return ContainerFactory.banksProvider;

                    var authState = dependencyContainer.resolve<IAuthState>(DependencyTokens.defaultAuthState);
                    return (ContainerFactory.banksProvider = new BankProvider(banksBaseUrl, tenantId, authState));
                }
            }
        );

        container.register<IBankRMProvider>(
            DependencyTokens.defaultBankRmsProvider,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.banksRMProvider)
                        return ContainerFactory.banksRMProvider;

                    var authState = dependencyContainer.resolve<IAuthState>(DependencyTokens.defaultAuthState);
                    return (ContainerFactory.banksRMProvider = new BankRmProvider(banksBaseUrl, tenantId, authState));
                }
            }
        );

        container.register<INotificationProvider>(
            DependencyTokens.defaultNotificationProvider,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.notificationsProvider)
                        return ContainerFactory.notificationsProvider;

                    var authState = dependencyContainer.resolve<IAuthState>(DependencyTokens.defaultAuthState);
                    return (ContainerFactory.notificationsProvider = new NotificationProvider(notificationBaseUrl, tenantId, authState));
                }
            }
        );
        container.register<ICalendarProvider>(
            DependencyTokens.defaultCalendarProvider,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.calendarProvider)
                        return ContainerFactory.calendarProvider;

                    var authState = dependencyContainer.resolve<IAuthState>(DependencyTokens.defaultAuthState);
                    return (ContainerFactory.calendarProvider = new CalendarProvider(notificationBaseUrl, tenantId, authState));
                }
            }
        );

        container.register<IEiborRatesProvider>(
            DependencyTokens.defaultEiborRatesProvider,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.eiborRatesProvider)
                        return ContainerFactory.eiborRatesProvider;

                    var authState = dependencyContainer.resolve<IAuthState>(DependencyTokens.defaultAuthState);
                    return (ContainerFactory.eiborRatesProvider = new EiborRatesProvider(banksBaseUrl, tenantId, authState));
                }
            }
        );

        container.register<IBankRulesProvider>(
            DependencyTokens.defaultBankRulesProvider,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.bankRulesProvider)
                        return ContainerFactory.bankRulesProvider;

                    var authState = dependencyContainer.resolve<IAuthState>(DependencyTokens.defaultAuthState);
                    return (ContainerFactory.bankRulesProvider = new BankRulesProvider(banksBaseUrl, tenantId, authState));
                }
            }
        );

        container.register<IApprovedDevelopersProvider>(
            DependencyTokens.defaultApprovedDevelopersProvider,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.approvedDevelopersProvider)
                        return ContainerFactory.approvedDevelopersProvider;

                    var authState = dependencyContainer.resolve<IAuthState>(DependencyTokens.defaultAuthState);
                    return (ContainerFactory.approvedDevelopersProvider = new ApprovedDeveloperProvider(banksBaseUrl, tenantId, authState));
                }
            }
        );

        container.register<IBankOfferProvider>(
            DependencyTokens.defaultBankOfferProvider,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.bankOfferProvider)
                        return ContainerFactory.bankOfferProvider;

                    var authState = dependencyContainer.resolve<IAuthState>(DependencyTokens.defaultAuthState);
                    return (ContainerFactory.bankOfferProvider = new BankOfferProvider(banksBaseUrl, tenantId, authState));
                }
            }
        );

        container.register<IDashboardProvider>(
            DependencyTokens.defaultDashboardProvider,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.dashboardProvider)
                        return ContainerFactory.dashboardProvider;

                    var authState = dependencyContainer.resolve<IAuthState>(DependencyTokens.defaultAuthState);
                    return (ContainerFactory.dashboardProvider = new DashboardProvider(leadsBaseUrl, tenantId, authState));
                }
            }
        );


        container.register<IQuotationProvider>(
            DependencyTokens.defaultQuotationProvider,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.quotationProvider)
                        return ContainerFactory.quotationProvider;

                    var authState = dependencyContainer.resolve<IAuthState>(DependencyTokens.defaultAuthState);
                    return (ContainerFactory.quotationProvider = new QuotationProvider(banksBaseUrl, tenantId, authState));
                }
            }
        );

        container.register<IQuoteProvider>(
            DependencyTokens.defaultQuoteProvider,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.quoteProvider)
                        return ContainerFactory.quoteProvider;

                    var authState = dependencyContainer.resolve<IAuthState>(DependencyTokens.defaultAuthState);
                    return (ContainerFactory.quoteProvider = new QuoteProvider(leadsBaseUrl, tenantId, authState));
                }
            }
        );


        container.register<ICodeGenerateProvider>(
            DependencyTokens.defaultCodegeneratorProvider,
            {
                useFactory: (dependencyContainer: DependencyContainer) => {
                    if (!!ContainerFactory.codeGeneratorProvider)
                        return ContainerFactory.codeGeneratorProvider;

                    var authState = dependencyContainer.resolve<IAuthState>(DependencyTokens.defaultAuthState);
                    return (ContainerFactory.codeGeneratorProvider = new CodeGenerateProvider(baseUrl, tenantId, authState));
                }
            }
        );

    }
}
