import { IApprovedDevelopersProvider } from './../interfaces/IApprovedDevelopersProvider';
import { IApprovedDeveloper } from './../models/approvedDevelopers';
import { PagedList } from "../models/pagedList";
import { ServiceBase } from "./ServiceBase";
import * as axios from "axios";
import { IAuthState } from "../interfaces/IAuthState";
import { ApprovedDeveloperCreationRq, ApprovedDeveloperDeletionRq, ApprovedDeveloperFetchRq, ApprovedDevelopersListingRq, ApprovedDeveloperStatusUpdationRq, ApprovedDeveloperUpdationRq } from '../models/approved-developers-request';
import { v4 } from 'uuid';

export class ApprovedDeveloperProvider extends ServiceBase implements IApprovedDevelopersProvider {


 constructor(baseUrl: string,  tenantId: string,  authState: IAuthState) {
    super(baseUrl, tenantId, authState);
}


    createAsync(req: ApprovedDeveloperCreationRq): Promise<IApprovedDeveloper> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);
            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
            return resp.data as IApprovedDeveloper;
        });
    }

    updateAsync(req: ApprovedDeveloperUpdationRq): Promise<IApprovedDeveloper> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);
            var resp = await axios.default.put(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
            return resp.data as IApprovedDeveloper;
        });
    }

    updateStatusAsync(req: ApprovedDeveloperStatusUpdationRq): Promise<IApprovedDeveloper>{
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);
            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
            return resp.data as IApprovedDeveloper;
        })
    }

    fetchApprovedDevelopersAsync(req: ApprovedDeveloperFetchRq): Promise<IApprovedDeveloper> {

        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IApprovedDeveloper;
        });
    }

    deleteAsync(req: ApprovedDeveloperDeletionRq): Promise<void> {


        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            await axios.default.delete(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }


    listApprovedDevelopersAsync(req: ApprovedDevelopersListingRq): Promise<PagedList<IApprovedDeveloper>> {

        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);
            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
            return {
                items: (resp.data.items ?? []).map((x: any) => Object.assign({}, x) as IApprovedDeveloper),
                pagingInfo: {
                    pageNum: resp.data.pagingInfo.pageNumber,
                    pageSize: resp.data.pagingInfo.pageSize,
                    totalItems: resp.data.pagingInfo.totalItems
                }
            }
        });
    }
}