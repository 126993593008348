import NavbarUser from "./NavbarUser";

export default function ThemeNavbar(props: any) {
  const { skin, setSkin, setMenuVisibility } = props;

  return (
    <>
      <NavbarUser skin={skin} setSkin={setSkin} setMenuVisibility={setMenuVisibility} />
    </>
  );
}