import { IEiborRates } from './../models/eiboreRates';
import { PagedList } from "../models/pagedList";
import { ServiceBase } from "./ServiceBase";
import * as axios from "axios";
import { IAuthState } from "../interfaces/IAuthState";
import { IEiborRatesProvider } from "../interfaces/IEiborRatesProvider";
import { EiborRatesBulkDeletionRq, EiborRatesCreationRq, EiborRatesDeletionRq, EiborRatesFetchRq, EiborRatesListingRq, EiborRatesUpdationRq } from '../models/eibore-rates-request';

export class EiborRatesProvider extends ServiceBase implements IEiborRatesProvider {
    constructor(baseUrl: string, tenantId: string, authState: IAuthState) {
        super(baseUrl, tenantId, authState);
    }

    createEiborRatesAsync(req: EiborRatesCreationRq): Promise<IEiborRates> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IEiborRates;
        });
    }

    updateEiborRatesAsync(req: EiborRatesUpdationRq): Promise<IEiborRates> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.put(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IEiborRates;
        });
    }

    fetchEiborRatesAsync(req: EiborRatesFetchRq): Promise<IEiborRates> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return resp.data as IEiborRates;
        });
    }

    deleteEiborRatesAsync(req: EiborRatesDeletionRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            await axios.default.delete(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }

    bulkDeleteEiborRatesAsync(req: EiborRatesBulkDeletionRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = req.getApiCallParams(this.baseUrl);

            await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
        });
    }


    listEiborRatesAsync(req: EiborRatesListingRq): Promise<PagedList<IEiborRates>> {
        return this.safelyExecuteAsync(async () => {
            var { url } = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

            return {
                items: (resp.data.items ?? []).map((x: any) => Object.assign({}, x) as IEiborRates),
                pagingInfo: {
                    pageNum: resp.data.pagingInfo.pageNumber,
                    pageSize: resp.data.pagingInfo.pageSize,
                    totalItems: resp.data.pagingInfo.totalItems
                }
            }
        });
    }

}