export enum Status {
    Active = "active",
    Suspended = "suspended",
    Pending = "pending-activation"
}

export enum UserType {
    Internal = "Internal",
    ReferralPartner = "ReferralPartner",
    NetworkPartnerIndividualUser = "NetworkPartnerIndividualUser",
    NetworkPartnerCompanyUser = "NetworkPartnerCompanyUser",
    WLPartnerUser = "WLPartnerUser",
    CSPartnerUser = "CSPartnerUser",
    Partner = "Partner"
}

export enum OrgType {
    NetworkPartnerCompany,
    CrossSellPartner,
    WhileLabelPartner
}

export enum EntityType {
    User = "user",
    Org = "org"
}

export enum UserCategories {
    Internal,
    NetworkPartner,
    ReferralPartner,
    WhiteLabelPartner,
    CrossSellPartner
}

export enum ImageType {
    ProfilePic = "profile-pic",
    Logo = "logo",
    Favicon = "favicon"
}

export enum SettingsType {
    General = "general",
    Dashboard = "dashboard",
    Role = "role",
    Smtp = "smtp",
    Status = "status",
    Leads = "leads",
    BankOffer = "bankoffer",
    EiboreSettings = "eiboresettings",
    EiboreMonths = "eiboremonths",
    Developers = "developers",
    Projects = "projects",
    Products = "products",
    BankOfferNotifications = "bankoffernotifications",
    DupLeadNotifications = "dupleadnotifications",
    LeadsPerUser = "leadsperuser",
    LeadsPerProduct = "leadsperproduct",
    LeadsPerNWUser = "leadspernwusers",

}


export enum NotificationsType {
    None = "none",
    NotificationsOnly = "notificationsonly",
    NotificationsAndEmail = "notificationsandemail"
}

export enum PeriodUnit {
    Days = "days",
    Weeks = "weeks",
    Months = "months"
}

export enum BankStatus {
    Active = "active",
    Suspended = "suspended"
}

export enum CodeGeneratorStatus {
    Active = "active",
    Suspended = "suspended"
}

export enum BankRMStatus {
    Active = "active",
    Suspended = "suspended"
}

export enum ApprovedDeveloperStatus {
    Active = "active",
    Inactive = "inactive",
    Closed = "closed"
}


// export enum BankOfferStatus {
//     Active = "active",
//     Inactive = "inactive",
//     Closed = "closed"
// }
export enum ResidentStatus {
    Resident = "resident",
    NonResident = "nonresident"
}

export enum LeadStatusType {
    Won = "won",
    Lost = "lost",
    Todo = "Todo",
    InProgress = "InProgress"

}
export enum EmploymentStatus {
    Salaried = "salaried",
    SelfEmployed = "selfemployed",
}

export enum DocumentType {
    Standard = "standard",
    Low = "low"
}

export enum SalaryTransfer {
    Yes = "yes",
    No = "no",
    NoPreference = "nopreference"
}

export enum UaeResident {
    Yes = "yes",
    No = "no",
}

export enum PreviousMortgage {
    Yes = "yes",
    No = "no"
}
export enum AssociateFees {
    Yes = "yes",
    No = "no"
}
export enum ComparisonOperators {
    Is = "is",
    IsNot = "isnot",
    Empty = "empty",
    IsNotEmpty = "isnotempty",
    Contains = "contains",
    DoesNotContain = "doesnotcontain",
    StartsWith = "startswith",
    EndsWith = "endswith",
    Between = "between",
    GTE = "gte",
    LTE = "lte",
    GT = "gt",
    LT = "lt"
}

export enum FieldType {
    Text = "text",
    Number = "number",
    Select = "select",
    MultiSelect = "multiselect"
}

export enum AggregationOptions {
    And = "and",
    Or = "or"
}

export enum BankRuleStatus {
    Enabled = "enabled",
    Disabled = "disabled"
}

export enum NationalityStatus {
    UaeNationals = "uaenationals",
    Expats = "expats"
}

export enum OccupationType {
    Salaried = "salaried",
    SelfEmployed = "selfemployed"
}

export enum InterestRateType {
    Fixed = "fixed",
    Variable = "variable",
    Either = "either"
}

export enum LoanPreference {
    Islamic = "islamic",
    Conventional = "conventional",
    None = "none"
}

export enum Title {
    Mr = "mr",
    Mrs = "mrs",
    Miss = "miss",
    Ms = "ms",
    Dr = "dr"
}

export enum BankOfferStatus {
    Active = "active",
    Suspended = "suspended",
    Expired = "expired",
    Grace = "grace",
    Closed = "closed",
    Pending = "pending"
}

export const LoanPreferenceDisplayNameMap = {
    [LoanPreference.Conventional]: "Conventional",
    [LoanPreference.Islamic]: "Islamic",
    [LoanPreference.None]: "No Preference"
}

export const NationalityStatusDisplayNameMap = {
    [NationalityStatus.UaeNationals]: "UAE",
    [NationalityStatus.Expats]: "Expats"
}


export const TitleDisplayNameMap = {
    [Title.Mr]: "Mr",
    [Title.Mrs]: "Mrs",
    [Title.Miss]: "Miss",
    [Title.Ms]: "Ms",
    [Title.Dr]: "Dr"
}

export const ResidentStatusDisplayNameMap = {
    [ResidentStatus.Resident]: "Resident",
    [ResidentStatus.NonResident]: "Non Resident"
}

export const EmploymentStatusDisplayNameMap = {
    [EmploymentStatus.Salaried]: "Salaried",
    [EmploymentStatus.SelfEmployed]: "Self Employed"
}

export const InterestRateTypeStatusDisplayNameMap = {
    [InterestRateType.Fixed]: "Fixed",
    [InterestRateType.Variable]: "Variable",
    [InterestRateType.Either]: "Fixed / Variable"
}

export const SalaryTransferDisplayNameMap = {
    [SalaryTransfer.No]: "No",
    [SalaryTransfer.NoPreference]: "No Preference",
    [SalaryTransfer.Yes]: "Yes"
}

export const DocumentTypeDisplayNameMap = {
    [DocumentType.Standard]: "Standard Document",
    [DocumentType.Low]: "Low Document"
}

export enum ClientFeeType {
    Fixed = "fixed",
    Percent = "percent"
}

export const PreviousMortgageDisplayNameMap = {
    [PreviousMortgage.Yes]: "Yes",
    [PreviousMortgage.No]: "No"
}


export enum BankOfferSettings {
    EmiratesOrCity = "emiratesorcity",
    PropertyLocation = "propertylocation",
    LoanType = "loantype",
    PropertyType = "propertytype",
    AssignedUsers = "assignedusers",
    MonthlyIncome = "monthlyincome",
    CompanyName = "companyname",
    Location = "location",
    Children = "children",
    MaritalStatus = "maritalstatus",
    InsuranceTenure = "insurancetenure",
    Smoker = "smoker",
    Comment = "comment",
    Purpose = "purpose",
}


export const BankOfferSettingsDisplayMap = {
    [BankOfferSettings.EmiratesOrCity]: "Emirates Or City",
    [BankOfferSettings.PropertyLocation]: "Property Location",
    [BankOfferSettings.LoanType]: "Loan Type",
    [BankOfferSettings.PropertyType]: "Property Type",
    [BankOfferSettings.AssignedUsers]: "Assigned Users",
    [BankOfferSettings.MonthlyIncome]: "Monthly Income",
    [BankOfferSettings.CompanyName]: "Company Name",
    [BankOfferSettings.Location]: "Location",
    [BankOfferSettings.Comment]: "Comment",
    [BankOfferSettings.Purpose]: "Purpose",
    [BankOfferSettings.Children]: "Children",
    [BankOfferSettings.MaritalStatus]: "Marital Status",
    [BankOfferSettings.InsuranceTenure]: "Insurance Tenure",
    [BankOfferSettings.Smoker]: "Smoker",
}