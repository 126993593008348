import { useContext, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Disc, X, Circle } from "react-feather";
import { DependencyContainer } from "tsyringe";
import { ContainerContext } from "../../../../contexts/containerContext";
import { DependencyTokens } from "../../../../settings";
import { IGeneralSettingsState } from "../../../../interfaces/IGeneralSettingsState";
import { IGeneralSettings } from "../../../../models/ISettings";

export default function VerticalMenuHeader(props: any) {
  const { menuCollapsed, setMenuCollapsed, setMenuVisibility, setGroupOpen, menuHover } = props;
  const container = useContext(ContainerContext) as DependencyContainer;
  const generalSettingsState = container.resolve<IGeneralSettingsState>(DependencyTokens.defaultGeneralSettingsState);
  const [generalSettings, setGeneralSettings] = useState<IGeneralSettings | null>(null);

  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([]);
  }, [menuHover, menuCollapsed]);

  useEffect(() => {
    generalSettingsState.getGeneralSettings()
      .then((settings: IGeneralSettings | null) => {
        setGeneralSettings(settings);
      });
    document.addEventListener("general-settings", (event: any) => {
      const settings: IGeneralSettings = event.detail;
      setGeneralSettings(settings);
    });
  }, []);

  const Toggler = () => {
    return !menuCollapsed ?
      (<Disc size={20} data-tour="toggle-icon" className="text-primary toggle-icon d-none d-xl-block" onClick={() => setMenuCollapsed(true)} />) :
      (<Circle size={20} data-tour="toggle-icon" className="text-primary toggle-icon d-none d-xl-block" onClick={() => setMenuCollapsed(false)} />)
  }

  return (
    <div className="navbar-header">
      <ul className="nav navbar-nav flex-row">
        <li className="nav-item mr-auto">
          <NavLink to="/" className="navbar-brand">
            <span className="brand-logo">
              <img src={generalSettings?.companyLogoUrl} alt="logo"
                style={menuCollapsed ? menuHover ?
                  { width: '96px', height: '36px' } : { width: '56px', height: '32px' } :
                  { width: '96px', height: '36px' }
                }
              />
            </span>
          </NavLink>
        </li>
        <li className="nav-item nav-toggle">
          <div className="nav-link modern-nav-toggle cursor-pointer">
            <Toggler />
            <X onClick={() => setMenuVisibility(false)} className="toggle-icon icon-x d-block d-xl-none" size={20} />
          </div>
        </li>
      </ul>
    </div>
  );
}