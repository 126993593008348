import { INotificationState } from "../interfaces/INotificationState";

export class NotificationState implements INotificationState {
  private listeners: (() => void)[] = [];

  triggerRefresh(): void {
  this.notifyAll();
  }

  onNotificationsChanged(callback: () => void): void {
    if(this.listeners.includes(callback))
            return;

        this.listeners.push(callback);

        this.notifyAll();
  }
  private notifyAll(){
    this.listeners.forEach(listener => {
        try{
            listener();


        }catch(err){console.log(err);}
    });
}

}