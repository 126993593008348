import { ApiRouteKeys, QueryParams } from "../settings";
import { ApiCallParams } from "./api-call-params";
import { ApiRequestBase } from "./api-request-base";
import { UserType } from "./enums";
import { UserRequestsBase } from "./user-requests-base";

export class SettingsListingRQ extends ApiRequestBase {
    constructor(
        public settingTypeFilter: string | null = null,
        public settingsTagFiller: string | null = null,
        public fetchAll: boolean = false,
        public pageNum: number | null,
        public pageSize: number | null
    ) {
        super();

        if (fetchAll == true && (!!pageNum || !!pageSize))
            throw new Error("fetchall and specific pagenum/pagesize are mutually exclusive");

        if ((this.settingTypeFilter = (this.settingTypeFilter ?? "").trim()).length == 0)
            this.settingTypeFilter = null;

        if ((this.settingsTagFiller = (this.settingsTagFiller ?? "").trim()).length == 0)
            this.settingsTagFiller = null;

        this.pageNum = this.pageNum ?? 1;
        this.pageSize = this.pageSize ?? 10;
    }

    protected attachQueryParams(url: string): string {
        var queryParams = [];
        if (!!this.pageNum)
            queryParams.push(`${QueryParams.PageNum}=${this.pageNum}`);
        if (!!this.pageSize)
            queryParams.push(`${QueryParams.PageSize}=${this.pageSize}`);
        if (this.fetchAll == true)
            queryParams.push(`${QueryParams.FetchAll}=true`);
        if (!!this.settingsTagFiller)
            queryParams.push(`${QueryParams.tag}=${this.settingsTagFiller}`);
        if (!!this.settingTypeFilter)
            queryParams.push(`${QueryParams.type}=${this.settingTypeFilter}`);

        url = `${url}?${queryParams.join('&')}`;

        return url;
    }

    public getApiCallParams(baseUrl: string): ApiCallParams {
        return {
            url: this.attachQueryParams(`${baseUrl}/${ApiRouteKeys.Tenants}/${ApiRouteKeys.Settings}`),
            content: null
        };
    }
}