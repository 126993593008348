import { ApiRouteKeys, QueryParams } from "../settings";
import { ApiCallParams } from "./api-call-params";
import { ApiRequestBase } from "./api-request-base";
import { INotification } from "./notification"

export class NotificationCreationRQ extends ApiRequestBase{
    constructor(private notification: INotification){
        super();

        if(!(!!notification))
            throw new Error("lead is mandatory");
    }

    getApiCallParams(baseUrl: string): ApiCallParams {
        return {
            url: `${baseUrl}/${ApiRouteKeys.Notifications}`,
            content: Object.assign({}, this.notification)
        };
    }
}

export class NotificationUpdationRQ extends ApiRequestBase{
    constructor(private notification: INotification){
        super();

        if(!(!!notification))
            throw new Error("lead is mandatory");

        if(!(!!notification.id))
            throw new Error("lead id is mandatory")
    }

    getApiCallParams(baseUrl: string): ApiCallParams {
        return {
            url: `${baseUrl}/${ApiRouteKeys.Notifications}/${this.notification.id}`,
            content: Object.assign({}, this.notification)
        };
    }
}

export class NotificationFetchRq extends ApiRequestBase{
    constructor(private notificationId: string ){
        super();

        if(!notificationId)
            throw new Error("notificationId is mandatory")
    }

    getApiCallParams(baseUrl: string): ApiCallParams {
        return {
            url: `${baseUrl}/${ApiRouteKeys.Notifications}/${this.notificationId}/${ApiRouteKeys.Read}`,
            content: null
        };
    }
}

export class DeleteNotificationRq extends ApiRequestBase{
    constructor(private userId: string, private notificationId: string){
          super();

          if(!userId && notificationId )
          throw new Error("userId & notificationId is mandatory")
      }

      getApiCallParams(baseUrl: string): ApiCallParams {
          return {
            url: `${baseUrl}/${ApiRouteKeys.Notifications}/${this.notificationId}`,
              content: null
          };
      }
  }


export class NotificationAllDeletionRq extends ApiRequestBase{
  constructor(private userId: string ){
        super();

        if(!userId)
        throw new Error("userId is mandatory")
    }

    getApiCallParams(baseUrl: string): ApiCallParams {
        return {
          url: `${baseUrl}/${ApiRouteKeys.Users}/${this.userId}/${ApiRouteKeys.Notifications}/${ApiRouteKeys.Delete}`,
            content: null
        };
    }
}

export class NotificationListingRq extends ApiRequestBase{
    constructor(
        private fetchRead: boolean | null = null,
        private fromTimestamp: number | null = null,
        private toTimestamp: number | null = null,
        public userId: string | null = null,
    ){
        super();
    }
    getApiCallParams(baseUrl: string): ApiCallParams {
        return {
            url: this.attachQueryParams(`${baseUrl}/${ApiRouteKeys.Users}/${this.userId}/${ApiRouteKeys.Notifications}`),
            content: null
        };
    }
    private attachQueryParams(url: string): string{
        var queryParams = [];

        if(!!this.fetchRead)
        queryParams.push(`${QueryParams.FetchRead}=${this.fetchRead}`);
    if(!!this.toTimestamp)
        if(!!this.fromTimestamp)
            queryParams.push(`${QueryParams.FromTimestamp}=${this.fromTimestamp}`);
        if(!!this.toTimestamp)
            queryParams.push(`${QueryParams.ToTimestamp}=${this.toTimestamp}`);

        url = `${url}?${queryParams.join('&')}`;

        return url;
    }
}