import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Bell,
  BarChart2,
  Calendar,
} from "react-feather";
import {
  Button,
  Badge,
  Media,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  FormText,
} from "reactstrap";
import { useHistory } from "react-router";
import {
  NotificationFetchRq,
  NotificationListingRq,
} from "../../../models/notification-request";
import { container } from "tsyringe";
import { INotificationProvider } from "../../../interfaces/INotificationProvider";
import { useEffect, useRef, useState } from "react";
import { DependencyTokens } from "../../../settings";
import { ToastState } from "../../../impl/ToastState";
import { IAuthInfo, IAuthState } from "../../../interfaces/IAuthState";
import moment from "moment";
import { INotificationState } from "../../../interfaces/INotificationState";
export default function NotificationDropdown() {
  const [notificationList, setNotificationList] = useState<any[]>([]);
  const notificationProvider = container.resolve<INotificationProvider>(
    DependencyTokens.defaultNotificationProvider
  );
  const [authInfo, setAuthInfo] = useState<IAuthInfo>();
  const authState = container.resolve<IAuthState>(
    DependencyTokens.defaultAuthState
  );
  const authInfoRef = useRef<IAuthInfo>();
  const notificationState = container.resolve<INotificationState>(
    DependencyTokens.defaultNotificationState
  );
  const [isOpenDropdown, setIsOPenDropDown] = useState(false);

  const notificationHandler = useRef<any>(() => {
    fetchNotificationList(authInfoRef.current?.user?.id as string);
  });

  const authHandler = useRef<any>((_authInfo: IAuthInfo) => {
    setAuthInfo(_authInfo);
    authInfoRef.current = _authInfo;
    fetchNotificationList(_authInfo.user?.id as string);
  });

  const [countResponse, setCountResponse] = useState<number>();
  const history = useHistory();

  const fetchNotificationList = async (userId: string) => {
    if (!!!userId) return;
    try {
      let fetchRead = false;
      var response = await notificationProvider.listNotificationAsync(
        new NotificationListingRq(fetchRead, moment().subtract(15, 'days').unix(), moment().unix(), userId)
      );
      setNotificationList(response?.items ?? []);
    } catch (e: any) {
      ToastState.instance.show({ type: "danger", message: e.toString() });
      console.error(e);
    } finally {
    }
  };

  const markRead = async (notificationId: any, isRead: Boolean) => {
    if (isRead === false) {
      try {
        await notificationProvider.fetchNotificationAsync(
          new NotificationFetchRq(notificationId)
        );
        history.push("/allnotification");
        setIsOPenDropDown(!isOpenDropdown);
        fetchNotificationList(authInfo?.user?.id as string);
      } catch (e: any) {
      } finally {
      }
    }
  };

  const renderNotificationItems = () => {
    return (
      <PerfectScrollbar
        component="li"
        className="media-list scrollable-container"
        options={{
          wheelPropagation: false,
        }}
      >
        {notificationList
          .filter((a: any) => a.isRead === false)
          .slice(0, 2)
          .map((item, index) => {
            return (
              <a
                key={index}
                className="d-flex"
                href="/"
                onClick={(e) => e.preventDefault()}
              >
                <Media
                  className={classnames("d-flex", {})}
                  onClick={() => markRead(item.id, item.isRead)}
                >
                  <>
                    <Media left>
                      {item.metadata.event === "Reminder" ? (
                        <>
                          {" "}
                          <Calendar size={20} />
                        </>
                      ) : (
                        <BarChart2 size={20} />
                      )}
                    </Media>
                    <Media body>
                      {item.metadata.event === "Reminder" ? (
                        <>
                          <h6>
                            {item.metadata.event} - &nbsp;
                            <small className="notification-text">
                              {item.metadata.title}{" "}
                            </small>
                            <small className="notification-text">
                              {item.metadata.description}&nbsp;
                            </small>
                            <small className="notification-text">
                              {item.metadata.additionalDetails?.location}
                              &nbsp;
                            </small>
                            | &nbsp;
                            <small className="notification-text">
                              {moment(
                                item.metadata.additionalDetails?.time * 1000
                              ).format("DD/MM/YYYY - HH:mm")}
                            </small>
                          </h6>
                        </>
                      ) : item.metadata.event === "leadCreated" ? (
                        <>
                          <h6>
                            New Lead - &nbsp;
                            <small className="notification-text">
                              {item.metadata.title}{" "}
                            </small>
                          </h6>
                        </>
                      ) : item.metadata.event === "leadUserAssigned" ? (
                        <>
                          <h6>
                            <div style={{ display: "flex" }}>
                              Lead Product RM Change- &nbsp;
                            </div>
                            <FormText color="dark" style={{ fontSize: "13px" }}>
                              {`${item.metadata?.description}`}&nbsp;
                            </FormText>{" "}
                          </h6>
                        </>
                      ) : item.metadata.event === "leadTransferred" ? (
                        <>
                          <h6>
                            Transfer Lead - &nbsp;
                            <small className="notification-text">
                              {item.metadata.title}{" "}
                            </small>
                            <small className="notification-text">
                              {item.metadata?.comments}&nbsp;
                            </small>
                          </h6>
                        </>
                      ) : item.metadata.event === "leadStatusChange" ? (
                        <>
                          <h6>
                            Lead Status - &nbsp;
                            <small className="notification-text">
                              {item.metadata.description}{" "}
                            </small>
                            <small className="notification-text">
                              {item.metadata?.comments}&nbsp;
                            </small>
                          </h6>
                        </>
                      ) : item.metadata.event === "leadComment" ? (
                        <>
                          <h6>
                            Lead Comment - &nbsp;
                            <small className="notification-text">
                              Added by {item.metadata?.user}{" "}
                            </small>
                            <small className="notification-text">
                              {item.metadata?.comments}&nbsp;
                            </small>
                          </h6>
                        </>
                      ) : item.metadata.event === "leadCallSummary" ? (
                        <>
                          <h6>
                            Lead Call Summary - &nbsp;
                            <small className="notification-text">
                              Added by {item.metadata?.user}{" "}
                            </small>
                            <small className="notification-text">
                              {item.metadata?.description}&nbsp;
                            </small>
                          </h6>
                        </>
                      ) : item.metadata.event === "leadProductContactChange" ? (
                        <>
                          <h6>
                            Lead Information Change - &nbsp;
                            <small className="notification-text">
                              updated by {item.metadata?.user}{" "}
                            </small>
                          </h6>
                        </>
                      ) : item.metadata.event === "leadProductChange" ? (
                        <>
                          <h6>
                            Lead Service Type Information Change - &nbsp;
                            <small className="notification-text">
                              updated by {item.metadata?.user}{" "}
                            </small>
                          </h6>
                        </>
                      ) : item.metadata.event === "leadDeleted" ? (
                        <>
                          <h6>
                            Lead Delete Requested- &nbsp;
                            <small className="notification-text">
                              {item.metadata?.description}{" "}
                            </small>
                          </h6>
                        </>
                      ) : item.metadata.event === "leadNextActionChange" ? (
                        <>
                          <h6>
                            Next Action- &nbsp;
                            <small className="notification-text">
                              {item.metadata?.title}{" "}
                            </small>
                          </h6>
                        </>
                      ) : item.metadata.event === "leadClientRmChange" ? (<>
                        <h6>
                          Lead Client RM- &nbsp;
                          <small className="notification-text">
                            {item.metadata?.title == "Change-lead-clientRm" ? "Lead Client RM Change" : "Lead Client RM"}{" "}
                          </small>
                        </h6>
                      </>)
                        : item.metadata.event === "offerSent" ? (<>
                          <h6>
                            New Offer- &nbsp;
                            <small className="notification-text">
                              {item.metadata?.title}
                            </small>
                          </h6>
                        </>)
                          :
                          (
                            <>
                              <h6>
                                Other- &nbsp;
                                <small className="notification-text">
                                  {item.metadata?.title}{" "}
                                </small>
                              </h6>
                            </>
                          )}
                    </Media>
                  </>
                </Media>
              </a>
            );
          })}
      </PerfectScrollbar>
    );
  };
  useEffect(() => {
    notificationState.onNotificationsChanged(notificationHandler.current);
    let unsubscribe = authState.onAuthChanged(authHandler.current);

    return () => unsubscribe();
  }, []);
  return (
    <UncontrolledDropdown
      tag="li"
      className="dropdown-notification nav-item mr-20"
      isOpen={isOpenDropdown}
      toggle={() => setIsOPenDropDown(!isOpenDropdown)}
    >
      <DropdownToggle
        tag="a"
        className="nav-link"
        href=""
        onClick={(e) => e.preventDefault()}
      >
        <Bell size={21} />
        {!!notificationList.filter((a: any) => a.isRead == false)?.length ? (
          <>
            <Badge pill color="warning" className="badge-up text-white">
              {notificationList.filter((a: any) => a.isRead == false)?.length}
            </Badge>
          </>
        ) : (
          ""
        )}
      </DropdownToggle>
      <DropdownMenu
        tag="ul"
        right
        className="dropdown-menu-media mt-0"
        style={{ width: "300px" }}
      >
        <li className="dropdown-menu-header">
          <DropdownItem className="d-flex" tag="div" header>
            <h4 className="notification-title mb-0 mr-auto">Notifications</h4>
            <Badge tag="div" color="light-primary" pill>
              {countResponse} New
            </Badge>
          </DropdownItem>
        </li>
        {renderNotificationItems()}
        <li className="dropdown-menu-footer">
          <Button
            style={{ backgroundColor: "#F5F5F5" }}
            outline
            block
            onClick={() => {
              history.push("/allnotification");
              setIsOPenDropDown(!isOpenDropdown);
            }}
          >
            View all{" "}
          </Button>
        </li>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
