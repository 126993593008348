import { IQuote } from './../models/quote';

import { ServiceBase } from "./ServiceBase";
import * as axios from "axios";
import { IAuthState } from "../interfaces/IAuthState";
import { QuoteFetchRq } from "../models/quote-request";
import { IQuoteProvider } from '../interfaces/IQuoteProvider';

export class QuoteProvider extends ServiceBase implements IQuoteProvider {
    private static quoteArr: IQuote[] = [{
              id:"1",
              title:"Quote 1",
              date:"22-11-2022",
              loanAmount: {
                  amount:340000,
                  currency:"AED"
              },
              ltv:2.48,
              rateType:"Fixed Rate",
              interestRate:2.98,
              type:"Convetional",
              bankName:"SBI",
              offerCode:"12345",
              tenor:"25 Years"
    },{
        id:"1",
        title:"Quote 1",
        date:"22-11-2022",
        loanAmount: {
            amount:340000,
            currency:"AED"
        },
        ltv:2.48,
        rateType:"Fixed Rate",
        interestRate:2.98,
        type:"Convetional",
        bankName:"SBI",
        offerCode:"12345",
        tenor:"25 Years"
},];
    constructor(baseUrl: string, tenantId: string, authState: IAuthState) {
        super(baseUrl, tenantId, authState);
    }


    fetchQuoteAsync(req: QuoteFetchRq): Promise<IQuote> {

      return Promise.resolve(
        QuoteProvider.quoteArr.find(x=> x.id == req.quoteId) as IQuote

       )

        // return this.safelyExecuteAsync(async () => {
        //     var { url } = req.getApiCallParams(this.baseUrl);

        //     var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

        //     return resp.data as IQuote;
        // });
    }



}