import { useState, useRef, useContext, useEffect } from "react";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";

import VerticalMenuHeader from "./VerticalMenuHeader";
import VerticalNavMenuItems from "./VerticalNavMenuItems";
import globalNavigation from "../../../../navigation/vertical";
import { IAuthInfo, IAuthState } from "../../../../interfaces/IAuthState";
import { DependencyContainer } from "tsyringe";
import { DependencyTokens } from "../../../../settings";
import { ContainerContext } from "../../../../contexts/containerContext";
import { UserType } from "../../../../models/enums";

export default function Sidebar(props: any) {
  const { menuCollapsed, routerProps, menu, currentActiveItem, skin } = props;
  const [groupOpen, setGroupOpen] = useState<any>([]);
  const [groupActive, setGroupActive] = useState<any>([]);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [menuHover, setMenuHover] = useState<boolean>(false);

  const shadowRef = useRef<any>(null);

  const container = useContext(ContainerContext) as DependencyContainer;
  const authState = container.resolve<IAuthState>(DependencyTokens.defaultAuthState);
  const authInfoRef = useRef<IAuthInfo>();

  var authHandler = useRef<any>((authInfo: IAuthInfo) => {
    authInfoRef.current = authInfo;
    if (!!authInfo?.token) {
      setFilteredNavigation(getNavigation(filterNavigation([...globalNavigation])));
    }
  });

  const getNavigation = (navigation: any[]): any => {
    if (authInfoRef.current?.userType == UserType.NetworkPartnerCompanyUser) {
      navigation.forEach(item => {
        if (item.id == "user") {
          item.navLink = `/network-partners-company/${authInfoRef?.current?.orgId}`;
        }
      });
    }
    return navigation;
  }

  const [filteredNavigation, setFilteredNavigation] = useState<any[]>([]);

  useEffect(() => {
    let unsubscribe = authState.onAuthChanged(authHandler.current);
    return () => unsubscribe();
  }, []);

  const filterNavigation = (items: any[]) => {
    var filtered: any[] = [];

    items.forEach(item => {
      if ((item.children ?? []).length == 0) {
        if (!!item.feature && authState.isAccessible(item.feature, null) == false)
          return;

        filtered.push(Object.assign({}, item));
        return;
      }

      var children = item.children.filter((child: any) => {
        if (!!child.feature)
          return authState.isAccessible(child.feature, null);

        return true;
      })

      if (children.length > 0)
        filtered.push(Object.assign({}, item, { children: children }));
    });

    return filtered;
  }

  // ** Function to handle Mouse Enter
  const onMouseEnter = () => {
    if (menuCollapsed) {
      setMenuHover(true);
    }
  }

  // ** Scroll Menu
  const scrollMenu = (container: any) => {
    if (shadowRef && container.scrollTop > 0) {
      if (!shadowRef.current.classList.contains("d-block")) {
        shadowRef.current.classList.add("d-block");
      }
    } else {
      if (shadowRef.current.classList.contains("d-block")) {
        shadowRef.current.classList.remove("d-block");
      }
    }
  }

  return (
    <>
      <div
        className={classnames("main-menu menu-fixed menu-accordion menu-shadow", {
          expanded: menuHover || menuCollapsed === false,
          "menu-light": skin !== "semi-dark" && skin !== "dark",
          "menu-dark": skin === "semi-dark" || skin === "dark"
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setMenuHover(false)}
      >
        {menu ? (
          menu(props)
        ) : (
          <>
            {/* Vertical Menu Header */}
            <VerticalMenuHeader setGroupOpen={setGroupOpen} menuHover={menuHover} {...props} />
            {/* Vertical Menu Header Shadow */}
            <div className="shadow-bottom" ref={shadowRef}></div>
            {/* Perfect Scrollbar */}
            <PerfectScrollbar
              className="main-menu-content"
              options={{ wheelPropagation: false }}
              onScrollY={container => scrollMenu(container)}
            >
              <ul className="navigation navigation-main">
                <VerticalNavMenuItems
                  items={filteredNavigation}
                  groupActive={groupActive}
                  setGroupActive={setGroupActive}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                  groupOpen={groupOpen}
                  setGroupOpen={setGroupOpen}
                  routerProps={routerProps}
                  menuCollapsed={menuCollapsed}
                  menuHover={menuHover}
                  currentActiveItem={currentActiveItem}
                />
              </ul>
            </PerfectScrollbar>
          </>
        )}
      </div>
    </>
  );
}