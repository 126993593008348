import { IBankOffer } from './../models/bankOffer';
import { ServiceBase } from "./ServiceBase";
import * as axios from "axios";
import { IAuthState } from "../interfaces/IAuthState";
import { BankOfferCreationRq, BankOfferDeletionRq, BankOfferFetchRq, BankOfferForDeveloperListingRq, BankOfferListingRq, BankOfferStatusUpdationRq, BankOfferUpdationRq, SendOfferDetailsRq } from '../models/bank-offer-request';
import { IBankOfferProvider } from '../interfaces/IBankOfferProvider';
import { PagedList } from '../models/pagedList';

export class BankOfferProvider extends ServiceBase implements IBankOfferProvider {
  constructor(baseUrl: string, tenantId: string, authState: IAuthState) {
    super(baseUrl, tenantId, authState);
  }

  createBankOfferAsync(req: BankOfferCreationRq): Promise<IBankOffer> {
    return this.safelyExecuteAsync(async () => {
      var { url, content } = req.getApiCallParams(this.baseUrl);

      var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });

      return resp.data as IBankOffer;
    });
  }

  updateBankOfferAsync(req: BankOfferUpdationRq): Promise<IBankOffer> {
    return this.safelyExecuteAsync(async () => {
      var { url, content } = req.getApiCallParams(this.baseUrl);
      var resp = await axios.default.put(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
      return resp.data as IBankOffer;
    });
  }

  fetchBankOfferAsync(req: BankOfferFetchRq): Promise<IBankOffer> {
    return this.safelyExecuteAsync(async () => {
      var { url } = req.getApiCallParams(this.baseUrl);

      var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });

      return resp.data as IBankOffer;
    });
  }

  updateStatusAsync(req: BankOfferStatusUpdationRq): Promise<IBankOffer> {
    return this.safelyExecuteAsync(async () => {
      var { url, content } = req.getApiCallParams(this.baseUrl);
      var resp = await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
      return resp.data as IBankOffer;
    })
  }


  deleteBankOfferAsync(req: BankOfferDeletionRq): Promise<void> {
    return this.safelyExecuteAsync(async () => {
      var { url } = req.getApiCallParams(this.baseUrl);

      await axios.default.delete(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
    });
  }

  listBankOfferAsync(req: BankOfferListingRq): Promise<PagedList<IBankOffer>> {
    return this.safelyExecuteAsync(async () => {
      var { url } = req.getApiCallParams(this.baseUrl);
      var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
      return {
        items: (resp.data.items ?? []).map((x: any) => Object.assign({}, x) as IBankOffer),
        pagingInfo: {
          pageNum: resp.data.pagingInfo.pageNumber,
          pageSize: resp.data.pagingInfo.pageSize,
          totalItems: resp.data.pagingInfo.totalItems
        }
      }
    });
  }

  listBankOfferForDeveloperAsync(req: BankOfferForDeveloperListingRq): Promise<IBankOffer[]> {
    return this.safelyExecuteAsync(async () => {
      var { url } = req.getApiCallParams(this.baseUrl);
      var resp = await axios.default.get(url, { headers: this.getHeaders(this.token as string, this.tenantId) });
      return (resp.data ?? []).map((x: any) => Object.assign({}, x) as IBankOffer);
    });
  }

  sendOfferDetailsAsync(req: SendOfferDetailsRq): Promise<void> {
    return this.safelyExecuteAsync(async () => {
      var { url, content } = req.getApiCallParams(this.baseUrl);

      await axios.default.post(url, content, { headers: this.getHeaders(this.token as string, this.tenantId) });
    });
  }
}