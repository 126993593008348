import { Fragment } from "react";
import classnames from "classnames";
import "animate.css/animate.css";

export default function LayoutWrapper({ layout, children, appLayout, wrapperClass, transition }: any) {
  const Tag = layout === "HorizontalLayout" && !appLayout ? "div" : Fragment;

  // ** Clean Up Function
  // const cleanUp = () => {
  //   if (routeMeta) {
  //     if (routeMeta.contentWidth) {
  //       dispatch(handleContentWidth("full"));
  //     }
  //     if (routeMeta.menuCollapsed) {
  //       dispatch(handleMenuCollapsed(!routeMeta.menuCollapsed));
  //     }
  //     if (routeMeta.menuHidden) {
  //       dispatch(handleMenuHidden(!routeMeta.menuHidden));
  //     }
  //   }
  // }

  // ** ComponentDidMount
  // useEffect(() => {
  //   if (routeMeta) {
  //     if (routeMeta.contentWidth) {
  //       dispatch(handleContentWidth(routeMeta.contentWidth));
  //     }
  //     if (routeMeta.menuCollapsed) {
  //       dispatch(handleMenuCollapsed(routeMeta.menuCollapsed));
  //     }
  //     if (routeMeta.menuHidden) {
  //       dispatch(handleMenuHidden(routeMeta.menuHidden));
  //     }
  //   }
  //   return () => cleanUp();
  // }, []);

  return (
    <div
      className={classnames("app-content content overflow-hidden", {
        [wrapperClass]: wrapperClass,
        "show-overlay": 0
      })}
    >
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow" />
      <div
        className={classnames({
          "content-wrapper": !appLayout,
          "content-area-wrapper": appLayout,
          "container p-0": "boxed",
          // [`animate__animated animate__${transition}`]: transition !== "none" && transition.length
        })}
      >
        <Tag
          /*eslint-disable */
          {...(layout === "HorizontalLayout" && !appLayout
            ? { className: classnames({ "content-body": !appLayout }) }
            : {})}
        /*eslint-enable */
        >
          {children}
        </Tag>
      </div>
    </div>
  );
}