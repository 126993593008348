import { lazy } from "react";
import { Features } from "../settings";

// ** Document title
export const TemplateTitle: string = "";

// ** Default Route
export const DefaultRoute: string = "/home";

// ** Merge Routes
export const Routes = [
  {
    path: "/home",
    component: lazy(() => import("../pages/dashboard")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Dashboard,
    },
  },
  {
    path: "/leads",
    component: lazy(() => import("../pages/lead")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.LeadManagement,
    },
  },
  {
    path: "/leads/source",
    component: lazy(() => import("../pages/lead/source")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.LeadSource,
    },
  },
  {
    path: "/leads/addLead",
    component: lazy(() => import("../pages/lead/addLead")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.LeadManagement,
    },
  },
  {
    path: "/leads/editLead/:leadId",
    component: lazy(() => import("../pages/lead/editLead")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.LeadManagement,
    },
  },
  {
    path: "/leads/calendar",
    component: lazy(() => import("../pages/lead/calendarLanding")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Calendar,
    },
  },

  {
    path: "/quotes",
    component: lazy(() => import("../pages/quote/quotesListing")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.QuoteGeneration,
    },
  },
  {
    path: "/quotes/add",
    component: lazy(() => import("../pages/quote/addEditQuote")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.NewQuoteGeneration,
    },
  },
  {
    path: "/quotes/edit/:quoteId",
    component: lazy(() => import("../pages/quote/addEditQuote")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.QuoteGeneration,
    },
  },
  {
    path: "/quotes/view/:quoteId",
    component: lazy(() => import("../pages/quote/quotation")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.QuoteGeneration,
    },
  },
  {
    path: "/calculator-bt",
    component: lazy(() => import("../pages/calculator/bt")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.BTCalculator,
    },
  },
  {
    path: "/calculator-emi",
    component: lazy(() => import("../pages/calculator/emi")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.EMICalculator,
    },
  },
  {
    path: "/calculator-iframesitetemplete",
    component: lazy(() => import("../pages/calculator/iFrameSiteTemplete")),
    layout: "BlankLayout",
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/banks",
    component: lazy(() => import("../pages/bank/")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Banks,
    },
  },
  {
    path: "/banks/addBank",
    component: lazy(() => import("../pages/bank/addBank")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Banks,
    },
  },
  {
    path: "/banks/editBank/:bankId",
    component: lazy(() => import("../pages/bank/addBank")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Banks,
    },
  },
  {
    path: "/banks/rm",
    component: lazy(() => import("../pages/bank/rm")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.BankRM,
    },
  },
  {
    path: "/banks/offers",
    component: lazy(() => import("../pages/bank/offer")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.BankOffer,
    },
  },

  {
    path: "/banks/rules",
    component: lazy(() => import("../pages/bank/rules-listing")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.BankRules,
    },
  },
  {
    path: "/banks/developer",
    component: lazy(() => import("../pages/bank/dev")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.BankApprovedDeveloper,
    },
  },
  {
    path: "/banks/viewDev/:id",
    component: lazy(() => import("../pages/bank/viewDev")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.BankApprovedDeveloper,
    },
  },
  {
    path: "/banks/addBankOffer",
    component: lazy(() => import("../pages/bank/addBankOffer")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.BankOffer,
    },
  },
  {
    path: "/banks/editBankOffer/:offerId",
    component: lazy(() => import("../pages/bank/addBankOffer")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.BankOffer,
    },
  },
  {
    path: "/banks/viewBankOffer/:offerId",
    component: lazy(() => import("../pages/bank/addBankOffer")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.BankOffer,
    },
  },
  {
    path: "/banks/viewOffer/:offerId",
    component: lazy(() => import("../pages/bank/bank-offer/offerOutput")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.BankOffer,
    },
  },
  {
    path: "/banks/addNewRules",
    component: lazy(() => import("../pages/bank/bankRule")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.BankRules,
    },
  },
  {
    path: "/banks/rules/:id",
    component: lazy(() => import("../pages/bank/bankRule")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.BankRules,
    },
  },
  {
    path: "/banks/rates",
    component: lazy(() => import("../pages/bank/rate")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.BankEiboreRate,
    },
  },
  {
    path: "/reports",
    component: lazy(() => import("../pages/report")),
    layout: "Layout",
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/referral-partners",
    component: lazy(() => import("../pages/referralPartner")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.ReferralPartners,
    },
  },
  {
    path: "/add-referral-partner",
    component: lazy(() => import("../pages/referralPartner/referralUser")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.ReferralPartners,
    },
  },
  {
    path: "/add-referral-partner/:id",
    component: lazy(() => import("../pages/referralPartner/referralUser")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.ReferralPartners,
    },
  },
  {
    path: "/internal-users",
    component: lazy(() => import("../pages/internalUser")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.InternalUsers,
    },
  },
  {
    path: "/create-internal-user",
    component: lazy(() => import("../pages/internalUser/addInternalUser")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.InternalUsers,
    },
  },
  {
    path: "/create-internal-user/:id",
    component: lazy(() => import("../pages/internalUser/addInternalUser")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.InternalUsers,
    },
  },
  {
    path: "/network-partners",
    component: lazy(() => import("../pages/networkPartner")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.NetworkPartners,
    },
  },
  {
    path: "/network-partners-company",
    component: lazy(() => import("../pages/networkPartner/company")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.NetworkPartners,
    },
  },
  {
    path: "/network-partners-company/:id",
    component: lazy(() => import("../pages/networkPartner/company")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.NetworkPartners,
    },
  },
  {
    path: "/network-partners-company-user/:companyId",
    component: lazy(() => import("../pages/networkPartner/companyUser")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.NetworkPartners,
    },
  },
  {
    path: "/network-partners-company-user/:companyId/:id",
    component: lazy(() => import("../pages/networkPartner/companyUser")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.NetworkPartners,
    },
  },
  {
    path: "/network-partners-individual",
    component: lazy(() => import("../pages/networkPartner/individual")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.NetworkPartners,
    },
  },
  {
    path: "/network-partners-individual/:id",
    component: lazy(() => import("../pages/networkPartner/individual")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.NetworkPartners,
    },
  },
  {
    path: "/white-labelling",
    component: lazy(() => import("../pages/whiteLabelling/whiteLabelListing")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.WLPartners,
    },
  },
  {
    path: "/create-white-labelling",
    component: lazy(
      () => import("../pages/whiteLabelling/addWhiteLabelPartner")
    ),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.WLPartners,
    },
  },
  {
    path: "/create-white-labelling/:id",
    component: lazy(
      () => import("../pages/whiteLabelling/addWhiteLabelPartner")
    ),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.WLPartners,
    },
  },
  {
    path: "/cross-sell-partners",
    component: lazy(
      () => import("../pages/crossSellPartners/crossSellPartnerListing")
    ),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.CrossSellPartners,
    },
  },
  {
    path: "/create-cross-sell-partners",
    component: lazy(
      () => import("../pages/crossSellPartners/addCrossSellPartner")
    ),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.CrossSellPartners,
    },
  },
  {
    path: "/create-cross-sell-partners/:id",
    component: lazy(
      () => import("../pages/crossSellPartners/addCrossSellPartner")
    ),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.CrossSellPartners,
    },
  },
  {
    path: "/users",
    component: lazy(() => import("../pages/user")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.UsersTab,
    },
  },
  {
    path: "/settings",
    component: lazy(() => import("../pages/settings")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Settings,
    },
  },
  {
    path: "/settings/general",
    component: lazy(() => import("../pages/settings/general")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Settings,
    },
  },
  {
    path: "/settings/dashboard",
    component: lazy(() => import("../pages/settings/dashboard")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Settings,
    },
  },
  {
    path: "/settings/role",
    component: lazy(() => import("../pages/settings/role")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Settings,
    },
  },
  {
    path: "/settings/smtp",
    component: lazy(() => import("../pages/settings/smtp")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Settings,
    },
  },
  {
    path: "/settings/status",
    component: lazy(() => import("../pages/settings/status")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Settings,
    },
  },
  {
    path: "/settings/leads",
    component: lazy(() => import("../pages/settings/leads")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Settings,
    },
  },
  {
    path: "/settings/offers",
    component: lazy(() => import("../pages/settings/offers")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Settings,
    },
  },
  {
    path: "/settings/eibor",
    component: lazy(() => import("../pages/settings/eibor")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Settings,
    },
  },
  {
    path: "/settings/notification",
    component: lazy(() => import("../pages/settings/notification")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Settings,
    },
  },
  {
    path: "/settings/developer",
    component: lazy(() => import("../pages/settings/developer")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Settings,
    },
  },
  {
    path: "/settings/products",
    component: lazy(() => import("../pages/settings/products")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Settings,
    },
  },
  {
    path: "/settings/systemInfo",
    component: lazy(() => import("../pages/settings/info")),
    layout: "Layout",
    meta: {
      authRoute: true,
      feature: Features.Settings,
    },
  },
  {
    path: "/profile",
    component: lazy(() => import("../pages/profile")),
    layout: "Layout",
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/register",
    component: lazy(() => import("../pages/onboard/register")),
    layout: "BlankLayout",
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/activate-account/:token",
    component: lazy(() => import("../pages/auth/activation")),
    layout: "BlankLayout",
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/login",
    component: lazy(() => import("../pages/auth/login")),
    layout: "BlankLayout",
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/forgot-password",
    component: lazy(() => import("../pages/auth/forgot")),
    layout: "BlankLayout",
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/reset-password/:token",
    component: lazy(() => import("../pages/auth/reset")),
    layout: "BlankLayout",
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/change-password",
    component: lazy(() => import("../pages/auth/change")),
    layout: "BlankLayout",
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/password-confirm",
    component: lazy(() => import("../pages/auth/password-confirm")),
    layout: "BlankLayout",
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/error",
    component: lazy(() => import("../pages/error")),
    layout: "BlankLayout",
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/allnotification",
    component: lazy(
      () => import("../layouts/components/navbar/AllNotification")
    ),
    layout: "Layout",
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/seeallNotification",
    component: lazy(
      () => import("../layouts/components/navbar/SeeAllNotification")
    ),
    layout: "Layout",
    meta: {
      authRoute: true,
    },
  },
];
