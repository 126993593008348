import { useContext, useEffect, useState } from "react";
import { DependencyContainer } from "tsyringe";
import { ContainerContext } from "../../../contexts/containerContext";
import { IGeneralSettingsState } from "../../../interfaces/IGeneralSettingsState";
import { IGeneralSettings } from "../../../models/ISettings";
import { DependencyTokens } from "../../../settings";

export default function Footer({ footerType }: any) {

  const container = useContext(ContainerContext) as DependencyContainer;
  const generalSettingsState = container.resolve<IGeneralSettingsState>(DependencyTokens.defaultGeneralSettingsState);
  const [generalSettings, setGeneralSettings] = useState<IGeneralSettings | null>(null);

  useEffect(() => {
    generalSettingsState.getGeneralSettings().then((settings: IGeneralSettings | null) => {
      setGeneralSettings(settings);
    });
    document.addEventListener("general-settings", (event: any) => {
      const settings: IGeneralSettings = event.detail;
      setGeneralSettings(settings);
    });
  }, []);

  return (
    <p className="clearfix mb-0" dangerouslySetInnerHTML={{ __html: generalSettings?.footerText || "" }}></p>
  );
}