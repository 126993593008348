import * as axios from "axios";
import { IAuthState } from "../interfaces/IAuthState";

type AsyncCallback<T> = () => Promise<T>;

export abstract class ServiceBase {
    protected token: string | null = null;

    constructor(protected baseUrl: string, protected tenantId: string, protected authState: IAuthState | null) {
        if ((baseUrl = (baseUrl ?? "").trim()).length == 0)
            throw new Error("base url is mandatory");

        if ((tenantId = (tenantId ?? "").trim()).length == 0)
            throw new Error("tenant is mandatory");

        axios.default.defaults.headers = {
            "tenantId": tenantId
        };

        authState?.onAuthChanged((authInfo) => {
            this.token = authInfo.token as string;
        });
    }

    protected async safelyExecuteAsync<T>(callback: AsyncCallback<T>): Promise<T> {
        try {
            return await callback();
        } catch (err: any) {
            console.log(err);
            if (!!err.response?.data?.error)
                throw err.response.data.error.toString();
            throw "Something went wrong. Please try in some time.";
        }
    }

    protected getHeaders(token: string, tenantId: string) {
        return {
            tenantId: tenantId,
            "authorization": `Bearer ${token}`
        };
    }
}