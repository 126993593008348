import { IBankOfferSettings, INotificationDupSettings, IProjectSettings, ISMTPSettings } from './../models/ISettings';
import { PagedList } from "../models/pagedList";
import { PagingInfo } from "../models/pagingInfo";
import * as axios from "axios";
// import { ApiRouteKeys } from "../settings";
import { ISettingsProvider } from "../interfaces/ISettingsProvider";
import {
    ISettings,
    IGeneralSettings,
    SettingsTransform,
    ISmptSetttings,
    INotificationSettings,
    IStatusSettings,
    IDashboardSettings,
    IDeveloperSettings,
    IProductServiceSettings,
    ILeadSourceSettings,
    EiBoreSettings,
    IRoleSettings,
    EiBoreMonthsSettings
} from "../models/ISettings";
import { SettingsListingRQ } from "../models/settings-listing-request";
import { SettingsType } from "../models/enums";
import { SettingsUpdateReq } from "../models/settings-update-request";
import { SettingsGetReq } from "../models/settings-get-request";
import { SettingsDeleteReq } from "../models/settings-delete-request";
import { SettingsCreationReq, SMTPVerifyReq } from "../models/settings-creation-request";
import { OfferTagType } from '../models/settings-enum';
import { ServiceBase } from './ServiceBase';
import { IAuthState } from '../interfaces/IAuthState';
import { ApiRouteKeys } from '../settings';

export class SettingsProvider extends ServiceBase implements ISettingsProvider {
    constructor(baseUrl: string, tenantId: string, authState: IAuthState) {
        super(baseUrl, tenantId, authState);
    }

    private getPagingInfo(respData: any): PagingInfo {
        return {
            pageNum: respData.pagingInfo.pageNumber,
            pageSize: respData.pagingInfo.pageSize,
            totalItems: respData.pagingInfo.totalItems
        }
    }

    async listSettings(settingsListingRQ: SettingsListingRQ): Promise<PagedList<ISettings>> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = settingsListingRQ.getApiCallParams(this.baseUrl);
            var resp = await axios.default.get(url, content);

            return {
                items: (resp.data.items ?? []).map((x: any) => Object.assign({ }, x) as ISettings),
                pagingInfo: this.getPagingInfo(resp.data)
            }
        });
    }

    async verifySMTP(req: SMTPVerifyReq): Promise<ISMTPSettings> {
        return this.safelyExecuteAsync(async () => {
          var { url, content } = req.getApiCallParams(this.baseUrl);
          var resp = await axios.default.post(url, content);
          return Object.assign({}, resp.data) as ISMTPSettings;
        });
      }

    async createSettings(settingsCreationReq: SettingsCreationReq): Promise<ISettings> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = settingsCreationReq.getApiCallParams(this.baseUrl);
            var resp = await axios.default.post(url, content);
            return Object.assign({ }, resp.data) as ISettings;
        });
    }

    async getSetting(settingsGetReq: SettingsGetReq): Promise<ISettings> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = settingsGetReq.getApiCallParams(this.baseUrl);
            var resp = await axios.default.get(url);
            return Object.assign({ }, resp.data) as ISettings;
        });
    }

    async deleteSetting(settingsDeleteReq: SettingsDeleteReq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = settingsDeleteReq.getApiCallParams(this.baseUrl);
            await axios.default.delete(url);
        });
    }

    async updateSetting(settingsUpdateReq: SettingsUpdateReq): Promise<ISettings> {
        return this.safelyExecuteAsync(async () => {
            var { url, content } = settingsUpdateReq.getApiCallParams(this.baseUrl);
            var resp = await axios.default.put(url, content);
            return Object.assign({ }, resp.data) as ISettings;
        });
    }

    async getGeneralSettings(): Promise<IGeneralSettings> {
        return this.safelyExecuteAsync(async () => {
            let getGeneralSettingsReq = new SettingsListingRQ(SettingsType.General, null, false, 0, 999);
            var res = await this.listSettings(getGeneralSettingsReq);
            return Object.assign({ }, SettingsTransform.toGeneralSettings(res?.items?.[0]))
        });
    }

    async getSmtpSettings(): Promise<ISmptSetttings> {
        return this.safelyExecuteAsync(async () => {
            let getGeneralSettingsReq = new SettingsListingRQ(SettingsType.Smtp, null, false, 0, 10);
            var res = await this.listSettings(getGeneralSettingsReq);
            return Object.assign({ }, SettingsTransform.toSmtpSettings(res?.items?.[0]))
        });
    }

    async getNotificationSettings(): Promise<PagedList<INotificationSettings>> {
        return this.safelyExecuteAsync(async () => {
            let getNotificationSettingsReq = new SettingsListingRQ(SettingsType.BankOfferNotifications, null, false, 0, 10);
            var resp = await this.listSettings(getNotificationSettingsReq);
            return {
                items: (resp.items ?? []).map((x: any) => SettingsTransform.toNotificationSettings(x)),
                pagingInfo: resp.pagingInfo
            }
        });
    }

    async getNotificationDupSettings(): Promise<PagedList<INotificationDupSettings>> {
        return this.safelyExecuteAsync(async () => {
            let getNotificationSettingsReq = new SettingsListingRQ(SettingsType.DupLeadNotifications, null, false, 0, 10);
            var resp = await this.listSettings(getNotificationSettingsReq);
            return {
                items: (resp.items ?? []).map((x: any) => SettingsTransform.toNotificationDupSettings(x)),
                pagingInfo: resp.pagingInfo
            }
        });
    }

    async getStatusSettings(): Promise<PagedList<IStatusSettings>> {
        return this.safelyExecuteAsync(async () => {
            let getStatusSettingsreq = new SettingsListingRQ(SettingsType.Status, null, false, 0, 999);
            var resp = await this.listSettings(getStatusSettingsreq);
            return {
                items: (resp.items ?? []).map((x: any) => SettingsTransform.toStatusSettings(x)),
                pagingInfo: resp.pagingInfo
            }
        });
    }

    async getDashboardSettings(): Promise<PagedList<IDashboardSettings>> {
        return this.safelyExecuteAsync(async () => {
            let getStatusSettingsreq = new SettingsListingRQ(SettingsType.Dashboard, null, false, 0, 999);
            var resp = await this.listSettings(getStatusSettingsreq);
            return {
                items: (resp.items ?? []).map((x: any) => SettingsTransform.toDashboardSettings(x)),
                pagingInfo: resp.pagingInfo
            }
        });
    }

    async getDeveloperSettings(): Promise<PagedList<IDeveloperSettings>> {
        return this.safelyExecuteAsync(async () => {
            let getStatusSettingsreq = new SettingsListingRQ(SettingsType.Developers, null, false, 0, 999);
            var resp = await this.listSettings(getStatusSettingsreq);
            return {
                items: (resp.items ?? []).map((x: any) => SettingsTransform.toDeveloperSettings(x)),
                pagingInfo: resp.pagingInfo
            }
        });
    }

    async getLeadSourceSettings(): Promise<PagedList<ILeadSourceSettings>> {
        return this.safelyExecuteAsync(async () => {
            let getStatusSettingsreq = new SettingsListingRQ(SettingsType.Leads, null, false, 0, 999);
            var resp = await this.listSettings(getStatusSettingsreq);
            return {
                items: (resp.items ?? []).map((x: any) => SettingsTransform.toLeadsSourceSettings(x)),
                pagingInfo: resp.pagingInfo
            }
        });
    }

    async getInternalUserSettings(): Promise<PagedList<IRoleSettings>> {
        return this.safelyExecuteAsync(async () => {
            let getStatusSettingsreq = new SettingsListingRQ(SettingsType.Role, "internal", false, 0, 999);
            var resp = await this.listSettings(getStatusSettingsreq);
            return {
                items: (resp.items ?? []).map((x: any) => SettingsTransform.toRoleSettings(x)),
                pagingInfo: resp.pagingInfo
            }
        });
    }

    async getNetworkPartnerSettings(): Promise<PagedList<IRoleSettings>> {
        return this.safelyExecuteAsync(async () => {
            let getStatusSettingsreq = new SettingsListingRQ(SettingsType.Role, "network", false, 0, 999);
            var resp = await this.listSettings(getStatusSettingsreq);
            return {
                items: (resp.items ?? []).map((x: any) => SettingsTransform.toRoleSettings(x)),
                pagingInfo: resp.pagingInfo
            }
        });
    }

    async getProductServiceSettings(): Promise<PagedList<IProductServiceSettings>> {
        return this.safelyExecuteAsync(async () => {
            let getStatusSettingsreq = new SettingsListingRQ(SettingsType.Products, null, false, 0, 999);
            var resp = await this.listSettings(getStatusSettingsreq);
            return {
                items: (resp.items ?? []).map((x: any) => SettingsTransform.toProductServiceSettings(x)),
                pagingInfo: resp.pagingInfo
            }
        });
    }

    async getEiBoreSettings(): Promise<PagedList<EiBoreSettings>> {
        return this.safelyExecuteAsync(async () => {
            let getStatusSettingsreq = new SettingsListingRQ(SettingsType.EiboreSettings, null, false, 0, 999);
            var resp = await this.listSettings(getStatusSettingsreq);
            return {
                items: (resp.items ?? []).map((x: any) => SettingsTransform.toEiBoreSettings(x)),
                pagingInfo: resp.pagingInfo
            }
        });
    }

    async getEiBoreMonthsSettings(): Promise<PagedList<EiBoreMonthsSettings>> {
        return this.safelyExecuteAsync(async () => {
            let getStatusSettingsreq = new SettingsListingRQ(SettingsType.EiboreMonths, null, false, 0, 999);
            var resp = await this.listSettings(getStatusSettingsreq);
            return {
                items: (resp.items ?? []).map((x: any) => SettingsTransform.toEiBoreMonthsSettings(x)),
                pagingInfo: resp.pagingInfo
            }
        });
    }

    async getBankOfferSettings(tag: string): Promise<PagedList<IBankOfferSettings>> {
        return this.safelyExecuteAsync(async () => {
            let getStatusSettingsreq = new SettingsListingRQ(SettingsType.BankOffer, tag, false, 0, 999);
            var resp = await this.listSettings(getStatusSettingsreq);
            return {
                items: (resp.items ?? []).map((x: any) => SettingsTransform.toBankOfferSettings(x)),
                pagingInfo: resp.pagingInfo
            }
        });
    }

    async getBankDocumentSettings(): Promise<PagedList<IBankOfferSettings>> {
        return this.safelyExecuteAsync(async () => {
            let getStatusSettingsreq = new SettingsListingRQ(SettingsType.BankOffer, OfferTagType.DOCUMENT, false, 0, 999);
            var resp = await this.listSettings(getStatusSettingsreq);
            return {
                items: (resp.items ?? []).map((x: any) => SettingsTransform.toBankDocumentSettings(x)),
                pagingInfo: resp.pagingInfo
            }
        });
    }


    async getProjectSettings(): Promise<PagedList<IProjectSettings>> {
        return this.safelyExecuteAsync(async () => {
            let getStatusSettingsreq = new SettingsListingRQ(SettingsType.Projects, null, false, 0, 999);
            var resp = await this.listSettings(getStatusSettingsreq);
            return {
                items: (resp.items ?? []).map((x: any) => SettingsTransform.toProjectSettings(x)),
                pagingInfo: resp.pagingInfo
            }
        });
    }

    saveLogoAsync(settingId: string, file: File): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var formData = new FormData();
            formData.append("logo", file);

            await axios.default.post(
                `${this.baseUrl}/${ApiRouteKeys.Tenants}/${ApiRouteKeys.Settings}/${settingId}/logo`,
                formData
            );
        });
    }

    removeLogoAsync(settingId: string): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            await axios.default.post(
                `${this.baseUrl}/${ApiRouteKeys.Tenants}/${ApiRouteKeys.Settings}/${settingId}/logo`,
                {
                    removeFile: true
                }
            );
        });
    }
}