import { ICalendarProvider } from './../interfaces/ICalendarProvider';
import { ICalendar } from './../models/calendar';

import { LeadSourceCreationRq, LeadSourceUpdationRq, LeadSourceListingRq, LeadSourceFetchRq, LeadSourceDeletionRq, LeadSourceBulkDeletionRq } from "../models/lead-source-requests";
import { PagedList } from "../models/pagedList";
import { ServiceBase } from "./ServiceBase";
import * as axios from "axios";
import { CalendarCreationRq, CalendarDeletionRq, CalendarFetchRq, CalendarListingRq, CalendarUpdationRq } from '../models/calendar-request';
import { IAuthState } from '../interfaces/IAuthState';

export class CalendarProvider extends ServiceBase implements ICalendarProvider{
    constructor(baseUrl: string, tenantId: string, authState: IAuthState) {
        super(baseUrl, tenantId, authState);
    }

    createCalendareAsync(req: CalendarCreationRq): Promise<ICalendar> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.post(url, content);

            return resp.data as ICalendar;
        });
    }

    updateCalendareAsync(req: CalendarUpdationRq): Promise<ICalendar> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.put(url, content);

            return resp.data as ICalendar;
        });
    }

    fetchCalendarAsync(req: CalendarFetchRq): Promise<ICalendar> {
        return this.safelyExecuteAsync(async () => {
            var {url} = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url);

            return resp.data as ICalendar;
        });
    }

    deleteCalendarAsync(req: CalendarDeletionRq): Promise<void> {
        return this.safelyExecuteAsync(async () => {
            var {url} = req.getApiCallParams(this.baseUrl);
            await axios.default.delete(url);
        });
    }


    listCalendarAsync(req: CalendarListingRq): Promise<PagedList<ICalendar>> {
        return this.safelyExecuteAsync(async () => {
            var {url, content} = req.getApiCallParams(this.baseUrl);

            var resp = await axios.default.get(url, content);

            return {
                items: (resp.data.items ?? []).map((x: any) => Object.assign({}, x) as ICalendar),
                pagingInfo: {
                    pageNum: resp.data.paginationInfo.pageNumber,
                    pageSize: resp.data.paginationInfo.pageSize,
                    totalItems: resp.data.paginationInfo.totalItems
                }
            }
        });
    }


}