import UserDropdown from "./UserDropdown";
import NotificationDropdown from "./NotificationDropdown";
import { Sun, Moon, Menu, Calendar } from "react-feather";
import { NavItem, NavLink } from "reactstrap";
import teq29 from "../../../assets/images/logo/teq29.png";

export default function NavbarUser(props: any) {
  const { skin, setSkin, setMenuVisibility } = props

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === "dark") {
      return <Sun className="ficon" onClick={() => setSkin("light")} />
    } else {
      // return <Moon className="ficon" onClick={() => setSkin("dark")} />
      return <Calendar className="ficon" />
    }
  }

  return (
    <>
      <ul className="navbar-nav d-xl-none d-flex align-items-center">
        <NavItem className="mobile-menu mr-auto">
          <NavLink
            className="nav-menu-main menu-toggle hidden-xs is-active"
            onClick={() => setMenuVisibility(true)}
          >
            <Menu className="ficon" />
          </NavLink>
        </NavItem>
      </ul>
      <div className="bookmark-wrapper d-flex align-items-center">
        <NavItem className="d-none d-lg-block">
          <NavLink className="nav-link-style">
            {/* <ThemeToggler /> */}
            <img src={teq29} alt="logo"
              style={{ height: "35px" }}
            />
          </NavLink>
        </NavItem>
      </div>
      <ul className="nav navbar-nav align-items-center ml-auto">
        <NotificationDropdown />
        <UserDropdown />
      </ul>
    </>
  );
}